import { orderBy } from "lodash-es";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";
import Table from "@kikoff/utils/src/table";

import { getBackendExperimentCookie } from "@src/experiments/context";
import { RootState } from "@store";

import { createLoadableSelector, thunk } from "../utils";

import { Bureau } from "./credit";

const initialState = {
  liveAccountById: {} as LiveAccount.ById,
  liveAccountIdsByBureau: null as Record<Bureau, LiveAccount.Id[]>,
};

export type LiveUtilizationState = typeof initialState;

const LiveUtilizationSlice = createSlice({
  name: "LiveUtilization",
  initialState,
  reducers: {
    setLiveAccounts(
      state,
      { payload: _liveAccounts }: PayloadAction<LiveAccount[]>
    ) {
      const liveAccounts = orderBy(
        _liveAccounts,
        LiveAccount.utilizationPercent,
        "desc"
      );
      state.liveAccountById = LiveAccount.ById.fromList(liveAccounts);

      const liveAccountIdsByBureau = Object.fromEntries(
        Bureau.list.map((bureau) => [bureau, []])
      ) as LiveUtilizationState["liveAccountIdsByBureau"];
      for (const liveAccount of liveAccounts) {
        const bureaus = liveAccount.bureaus.map(
          (bureau) => Bureau.byProtoEnum[bureau]
        );

        for (const bureau of bureaus) {
          liveAccountIdsByBureau[bureau].push(liveAccount.id);
        }
      }
      state.liveAccountIdsByBureau = liveAccountIdsByBureau;
    },
  },
});

const { actions } = LiveUtilizationSlice;
export const {} = actions;
export default LiveUtilizationSlice.reducer;

const selectLiveUtilizationLoaded = () => (state: RootState) => {
  const enabled = isLiveUtilizationEnabled();
  const dataLoaded = !!state.liveUtilization.liveAccountIdsByBureau;

  return !enabled || dataLoaded;
};

export const selectLiveAccounts = createLoadableSelector(
  (bureau: Bureau) => (state: RootState) =>
    state.liveUtilization.liveAccountIdsByBureau?.[bureau].map(
      (id) => state.liveUtilization.liveAccountById[id]
    ),
  {
    loadAction: () => fetchLiveUtilization(),
    selectLoaded: selectLiveUtilizationLoaded,
  }
);

export const selectLiveAccount = createLoadableSelector(
  (id: LiveAccount.Id) => (state: RootState) =>
    state.liveUtilization.liveAccountById[id],
  {
    loadAction: () => fetchLiveUtilization(),
    selectLoaded: selectLiveUtilizationLoaded,
  }
);

export const fetchLiveUtilization = Object.assign(() =>
  thunk((dispatch) =>
    webRPC.Credit.getLiveCreditUtilization({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          const liveAccounts = data.accounts;
          dispatch(actions.setLiveAccounts(liveAccounts));
          return liveAccounts;
        },
        _DEFAULT: handleFailedStatus("Failed to load live utilization."),
      })
    )
  )
);

export const isLiveUtilizationEnabled = () =>
  getBackendExperimentCookie("liveUtilizationMonitor") === "candidate";

export namespace LiveUtilization {
  export const idealUtilizationPercentLimit = 30;
  export const isIdealUtilization = (utilizationPercent: number) =>
    utilizationPercent <= LiveUtilization.idealUtilizationPercentLimit;
  export const utilizationColor = (utilizationPercent: number) =>
    isIdealUtilization(utilizationPercent) ? "primary" : "error";

  export const formatUtilizationPercent = (
    balanceCents: number,
    creditLimitCents: number
  ) => {
    const percent = (balanceCents / creditLimitCents) * 100;

    if (percent <= 0) {
      return 0;
    }
    if (percent < 1) {
      return Math.max(Math.round(percent * 10) / 10, 0.1);
    }
    return Math.round(percent);
  };

  export const hasLinkedAccount = (liveAccounts: LiveAccount[] | null) =>
    liveAccounts?.some(
      (liveAccount) =>
        LiveAccount.isTracked(liveAccount) &&
        !LiveAccount.isCreditAccount(liveAccount)
    );

  export const hasNotTrackedAccount = (liveAccounts: LiveAccount[] | null) =>
    liveAccounts?.some(LiveAccount.isNotTracked);
}

export type LiveAccount = web.public_.ILiveAccount;
export namespace LiveAccount {
  export type Id = string & {};

  export type ById = Record<LiveAccount.Id, LiveAccount>;
  export namespace ById {
    export const fromList = (liveAccounts: LiveAccount[]) =>
      Table.createIndex(liveAccounts, ["id"]);
  }

  export const latestBalance = ({
    balanceHistory,
  }: LiveAccount): web.public_.LiveAccount.IBalanceHistory => {
    const latestBalance = balanceHistory.at(-1);

    return { ...latestBalance, balanceCents: latestBalance.balanceCents ?? 0 };
  };

  export const updatedAt = (liveAccount: LiveAccount) =>
    LiveAccount.latestBalance(liveAccount).checkedAt;

  export const utilizationPercent = (liveAccount: LiveAccount) => {
    const { balanceCents, creditLimitCents } = LiveAccount.latestBalance(
      liveAccount
    );

    return LiveUtilization.formatUtilizationPercent(
      balanceCents,
      creditLimitCents
    );
  };

  export const type = (liveAccount: LiveAccount) => {
    // TODO(live-utilization): MVP supports only credit cards and CA
    if (LiveAccount.isCreditAccount(liveAccount)) return "Charge Account";

    return "Credit Card";
  };

  export const isTracked = (liveAccount: LiveAccount) =>
    [
      web.public_.LiveAccount.Status.LINKED,
      web.public_.LiveAccount.Status.RELINK,
    ].includes(liveAccount.status);

  export const isNotTracked = (liveAccount: LiveAccount) =>
    !LiveAccount.isTracked(liveAccount);

  export const isCreditAccount = ({ name }: LiveAccount) =>
    name === "Kikoff Credit Account";

  export const format = ({ name, lastFour }: LiveAccount) =>
    `${name}${lastFour ? ` ••••${lastFour}` : ""}`;
}
