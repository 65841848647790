import createExperiment from "./createExperiment";

export default createExperiment({
  variants: {
    control: {},
    alwaysClickable: {},
  },
}).weigh({
  control: 50,
  alwaysClickable: 50,
});
