import Router from "next/router";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import {
  CANCELATION_ONE_MONTH_OFFER,
  CANCELATION_ONE_MONTH_OFFER_PREMIUM,
  EIGHTY_PERCENT_OFF,
  EIGHTY_PERCENT_OFF_PREMIUM,
  FIRST_MONTH_DISCOUNT_PROMO,
  FIRST_MONTH_DISCOUNT_PROMO_PREMIUM,
  FIRST_MONTH_FREE,
  FIRST_MONTH_FREE_PREMIUM,
  FREE_BASIC_DISCOUNTED_PREMIUM,
  LAST_MONTH_FREE_PROMO,
  LAST_MONTH_FREE_PROMO_PREMIUM,
  PromoName,
  SIX_MONTH_EIGHTY_PERCENT_OFF,
  SIX_MONTH_EIGHTY_PERCENT_OFF_PREMIUM,
  SIX_MONTH_EIGHTY_PERCENT_OFF_ULTIMATE,
} from "@constant/promos";
import Impact from "@src/utils/impact";
import { RootState } from "@store";
import analytics, { track } from "@util/analytics";
import { nativeDispatch } from "@util/mobile";

import { createLoadableSelector, thunk } from "../utils";

import {
  setClosedCreditLineAccounts,
  setCreditLineAccount,
} from "./credit_line";
import { initWallet } from "./funds";
import { updateLoans } from "./loans";
import { initOnboarding } from "./onboarding";
import { fetchSiteVars, setRedirectTarget } from "./page";
import {
  fetchPreviewChangeSubscriptionPlan,
  selectIsPremium,
} from "./shopping";
import { Plan } from "./subscription";

const initialState = {
  authenticated: null as boolean,
  introPromo: null as Promo,
  mfaRequired: null as boolean,
  proto: null as Omit<web.public_.IUser, "openCreditLine" | "promos"> & {
    promos?: Promo[] | null;
  },
  refereeInfo: null as web.public_.GetReferrerInfoResponse,
  referrerInfo: null as web.public_.GetReferralInfoResponse,
  canAccessCommunity: undefined as boolean,
};

export type UserState = typeof initialState;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserState(state, { payload }: PayloadAction<Partial<UserState>>) {
      Object.assign(state, payload);
    },
    updateUser(state, { payload }: PayloadAction<Partial<UserState["proto"]>>) {
      state.proto ??= {};

      Object.assign(state.proto, payload);
    },
    setRefereeInfo(
      state,
      { payload }: PayloadAction<UserState["refereeInfo"]>
    ) {
      state.refereeInfo = payload;
    },
    setReferrerInfo(
      state,
      { payload }: PayloadAction<UserState["referrerInfo"]>
    ) {
      state.referrerInfo = payload;
    },
  },
});

const { actions } = userSlice;
export const { updateUserState } = actions;
export default userSlice.reducer;

export const selectUserToken = () => (state: RootState) =>
  state.user.proto?.token;

export const selectFirstName = () => (state: RootState) =>
  state.user.proto.info.givenName;

export const selectUserInfo = () => (state: RootState) => state.user.proto.info;

export type Promo = Omit<web.public_.Promo, "name"> & { name: PromoName };

export const selectPromo = (name: PromoName) => (state: RootState) => {
  return state.user.proto?.promos?.find((promo) => {
    return promo.name === name;
  }) as Promo;
};

export const selectCancellationPromo = () => (state: RootState) => {
  const isPremium = selectIsPremium()(state);

  return isPremium
    ? selectPromo(CANCELATION_ONE_MONTH_OFFER_PREMIUM)(state)
    : selectPromo(CANCELATION_ONE_MONTH_OFFER)(state);
};

export const selectCanAccessCommunity = createLoadableSelector(
  () => (state: RootState) => {
    return state.user.canAccessCommunity;
  },
  {
    loadAction: () => fetchCommunityAccess(),
  }
);

// expose_intro_test_offer endpoint returns the offer with the highest priority
//
// Use selectPromo if user is authenticated. The anonymous promo will be persisted
// to the promos under user after authentication _if_ the user is still eligible.

export const selectIntroPromo = () => (state: RootState) => {
  return state.user.introPromo as Promo;
};

export const selectCheckoutPromos = () => (state: RootState) => ({
  basic: selectBasicCheckoutPromo()(state),
  premium: selectPremiumCheckoutPromo()(state),
  ultimate: selectUltimateCheckoutPromo()(state),
});

export const selectBasicCheckoutPromo = () => (state: RootState) => {
  let promo = selectPromo(FREE_BASIC_DISCOUNTED_PREMIUM)(state);

  const priorityList = [
    SIX_MONTH_EIGHTY_PERCENT_OFF,
    EIGHTY_PERCENT_OFF,
    FIRST_MONTH_FREE,
    FIRST_MONTH_DISCOUNT_PROMO,
    LAST_MONTH_FREE_PROMO,
  ];

  for (const promoName of priorityList) {
    promo ||= selectPromo(promoName as PromoName)(state);
  }

  return promo;
};

export const selectPremiumCheckoutPromo = () => (state: RootState) => {
  let promo = selectPromo(FREE_BASIC_DISCOUNTED_PREMIUM)(state);

  const priorityList = [
    SIX_MONTH_EIGHTY_PERCENT_OFF_PREMIUM,
    EIGHTY_PERCENT_OFF_PREMIUM,
    FIRST_MONTH_FREE_PREMIUM,
    FIRST_MONTH_DISCOUNT_PROMO_PREMIUM,
    LAST_MONTH_FREE_PROMO_PREMIUM,
  ];

  for (const promoName of priorityList) {
    promo ||= selectPromo(promoName as PromoName)(state);
  }

  return promo;
};

export const selectUltimateCheckoutPromo = () => (state: RootState) => {
  return (
    selectPromo(SIX_MONTH_EIGHTY_PERCENT_OFF_ULTIMATE)(state) ||
    selectPromo(FREE_BASIC_DISCOUNTED_PREMIUM)(state)
  );
};

export const selectFirstMonthCheckoutPromo = () => (state: RootState) => {
  if (!state.shopping.checkoutPreview) {
    return;
  }

  const priorityList = [
    SIX_MONTH_EIGHTY_PERCENT_OFF,
    EIGHTY_PERCENT_OFF,
    FIRST_MONTH_FREE,
    FIRST_MONTH_DISCOUNT_PROMO,
  ];

  const plan =
    Plan.byProto[
      state.shopping.checkoutPreview.order.orderItems[0].product.plan
    ];

  let promo = selectPromo(FREE_BASIC_DISCOUNTED_PREMIUM)(state);

  for (const promoName of priorityList) {
    promo ||= selectPromo(
      (promoName + (plan === "basic" ? "" : `_${plan}`)) as PromoName
    )(state);
  }

  return promo;
};

export const selectIsFreemium = () => (state: RootState) =>
  !state.creditLine.account;

interface InitUserOptions {
  user?: web.public_.IUser;
  fields?: string[];

  delay?: number;
}

export const selectEligibleForScUpsell = () => (state: RootState) => {
  return state.creditLine.account || state.user.proto.hasActiveSecuredCard;
};

export const selectIsRentReportingEnabled = () => (state: RootState) =>
  state.user.proto.rentReportingEnabled;

export const selectHasActiveSecuredCard = () => (state: RootState) =>
  state.user.proto.hasActiveSecuredCard;

const partialUser = (user: web.public_.IUser, userFields: string[]) =>
  Object.fromEntries(
    userFields.map((field) => {
      // Snake to camel case except for numbers to match protobuf.js
      // e.g. address_line_1 => addressLine_1
      field = field.replace(/_[a-z]/gi, (s) => s.slice(1).toUpperCase());

      if (process.env.NODE_ENV === "development" && !(field in user))
        throw new Error(
          `Field ${field} not in user, this is likely a bug with the case transform above not matching up with protobuf.js's case transform, please update. Available keys include ${Object.keys(
            user
          ).join(", ")}`
        );
      return [field, user[field]];
    })
  );

export const fetchUser = (() => {
  const { Scope } = web.public_.User;
  type Scope = web.public_.User.Scope;

  const withConfig = ({ scopes = [] as Scope[] }) =>
    thunk((dispatch, getState) =>
      webRPC.Account.getUserInfo({ scopes }).then(({ user, userFields }) => {
        if (!user) {
          dispatch(updateUserState({ authenticated: false }));
          return;
        }

        const partial = partialUser(user, userFields);
        dispatch(actions.updateUser(partial));

        if ("wallet" in partial) dispatch(initWallet(partial));
        if ("openLoans" in partial)
          dispatch(updateLoans(partial.openLoans, partial.closedLoans));
        if ("openCreditLine" in partial)
          dispatch(setCreditLineAccount(partial.openCreditLine));
        if ("closedCreditLine" in partial)
          dispatch(setClosedCreditLineAccounts(partial.closedCreditLines));

        identifyUser(user, getState());

        return user;
      })
    );

  return {
    all: () => withConfig({ scopes: [Scope.ALL] }),
    firstParty: () => withConfig({ scopes: [Scope.FIRST_PARTY_INFO] }),
    todos: () =>
      // Todos are only displayed on dashboard, only need to invalidate when on mobile
      nativeDispatch("invalidate")
        ? thunk((_, getState) =>
            Promise.resolve(
              getState().user.proto as never // Adopt type of other branch
            )
          )
        : withConfig({ scopes: [Scope.TODOS] }),
    creditLine: () =>
      thunk((dispatch, getState) =>
        Promise.all([
          dispatch(withConfig({ scopes: [Scope.CREDIT_LINE, Scope.TODOS] })),
          ...Object.keys(
            getState().shopping.previewChangeSubscriptionPlan
          ).map((key) => dispatch(fetchPreviewChangeSubscriptionPlan(+key))),
        ]).then(([user]) => user)
      ),
    loans: () => withConfig({ scopes: [Scope.LOANS, Scope.TODOS] }),
    products: () => withConfig({ scopes: [Scope.PRODUCTS, Scope.TODOS] }),
    address: () =>
      withConfig({ scopes: [Scope.BASIC_INFO, Scope.ADDRESS_INFO] }),
    pii: () => withConfig({ scopes: [Scope.BASIC_INFO] }),
    persona: () => withConfig({ scopes: [Scope.PERSONA] }),
    intercom: () => withConfig({ scopes: [Scope.INTERCOM] }),
    features: () => withConfig({ scopes: [Scope.FEATURES] }),
  };
})();

function identifyUser(user: web.public_.IUser, state: RootState) {
  analytics.identify(user.token);
  Impact.identify(user.token, user.info?.email);
}

export const initUser = ({
  user: _user,
  fields,
  delay,
}: InitUserOptions = {}) => (dispatch, getState) => {
  function init(user: web.public_.IUser, fields: string[]) {
    if (!user) {
      dispatch(
        updateUserState({
          authenticated: false,
        })
      );
      return;
    }
    identifyUser(user, getState());
    dispatch(actions.updateUser(partialUser(user, fields)));

    const updateState = () => {
      dispatch(initWallet(user.wallet));
      dispatch(updateLoans(user.openLoans, user.closedLoans));
      dispatch(setCreditLineAccount(user.openCreditLine));
      dispatch(setClosedCreditLineAccounts(user.closedCreditLines));
      dispatch(
        updateUserState({
          proto: user,
          authenticated: true,
        })
      );
    };
    if (delay) setTimeout(updateState, delay);
    else updateState();
    return user;
  }
  // This only fetches site-vars if _user is falsey, assumes site-vars will be fetched externally if _user is defined
  return _user
    ? Promise.resolve(init(_user, fields))
    : Promise.all([
        webRPC.Account.getUserInfo({
          scopes: [web.public_.GetUserInfoRequest.Scope.FIRST_PARTY_INFO],
        }),
        dispatch(fetchSiteVars()),
      ]).then(([userRes]) => init(userRes.user, userRes.userFields));
};

export const userStatusRedirect = () => (dispatch, getState) => {
  const { user } = getState();

  if (user.mfaRequired) {
    if (Router.pathname !== "/login") {
      return Router.replace("/login");
    }
    return;
  }

  if (user.authenticated) {
    const status = web.public_.User.Status[user.proto.status];

    if (user.proto.info && !user.proto.info.emailConfirmed) {
      return dispatch(initOnboarding()).catch(() => {
        Router.replace("/onboarding");
      });
    }

    switch (status) {
      case "ONBOARDING":
        return dispatch(initOnboarding()).catch(() => {
          Router.replace("/onboarding");
        });
      case "POST_ONBOARDING":
        return Router.replace("/onboarding/post/selection");
      case "ONBOARDED": {
        const topLevelRoute = window.location.pathname.split("/")[1];
        if (["dashboard", "credit_monitor"].includes(topLevelRoute)) {
          return;
        }

        const searchParams = new URLSearchParams(window.location.search);
        let redirect = searchParams.get("origin");
        searchParams.delete("origin");

        // Redirect using state if unspecified in url
        if (!redirect) {
          redirect = getState().page.redirectTarget;
          dispatch(setRedirectTarget(null));
        } else {
          const url = new URL(redirect, window.location.origin);
          url.search = searchParams.toString();
          redirect = url.toString();
        }
        if (redirect) return Router.replace(redirect);
        return Router.replace("/dashboard");
      }
      case "WAITLISTED":
        return Router.replace("/onboarding/waitlist");
      default:
        console.error(new Error(`User status not handled: ${status}`)); // eslint-disable-line no-console
    }
  }
};

export const fetchRefereeInfo = (referrerToken: string) =>
  thunk(async (dispatch) => {
    return webRPC.Referrals.getReferrerInfo({ referrerToken }).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.setRefereeInfo(data));
        },
        _DEFAULT: handleFailedStatus("Failed to fetch referral info"),
      })
    );
  });

export const fetchReferrerInfo = () =>
  thunk(async (dispatch) => {
    return webRPC.Referrals.getReferralInfo({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.setReferrerInfo(data));
        },
        _DEFAULT: handleFailedStatus("Failed to fetch referral details"),
      })
    );
  });
export const login = (email: string, password: string) => (dispatch) => {
  return webRPC.Auth.login({
    email,
    password,
  }).then(
    handleProtoStatus({
      SUCCESS: (data) => data,
      _DEFAULT: handleFailedStatus("Login failed"),
    })
  );
};

export const logout = (redirectUrl: string = null) => (dispatch) => {
  return webRPC.Auth.logout({}).then(
    handleProtoStatus({
      SUCCESS() {
        analytics.anonymize();
        track("Auth - Logged Out");
        // HACK: We need to set Auth to a falsy value that is not `null` or `false`,
        // since we want to prevent route enforcement and redirect to login.
        dispatch(updateUserState({ authenticated: 0 }));
        (redirectUrl ? Router.replace(redirectUrl) : Promise.resolve()).then(
          () => {
            dispatch({ type: "RESET" });
          }
        );
      },
      _DEFAULT: handleFailedStatus(
        "Failed to log out, please contact customer support"
      ),
    })
  );
};

export const impersonationEnd = () => (dispatch) => {
  return webRPC.Auth.impersonationEnd({}).then(
    handleProtoStatus({
      SUCCESS() {
        analytics.anonymize();
        track("Auth - Impersonation End");
        dispatch({ type: "RESET" });
      },
      _DEFAULT: handleFailedStatus("Failed to end impersonation"),
    })
  );
};

export const fetchCommunityAccess = () =>
  thunk(async (dispatch) => {
    return webRPC.Community.getCommunityAccess({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(updateUserState({ canAccessCommunity: data.allowed }));
        },
        _DEFAULT: handleFailedStatus("Failed to fetch community access"),
      })
    );
  });
