import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import Router from "next/router";
import Url from "url-parse";

import { InteractiveError } from "@kikoff/hooks/src/useError";
import { web } from "@kikoff/proto/src/protos";

import AtomicFiAction from "@component/legacy_proto_parser/actions/atomic-fi";
import { NavigationHistory } from "@component/legacy_proto_parser/helpers/handle-proto-url";
import { initCreditV2 } from "@feature/credit";
import { dismiss } from "@feature/page";
import { AppStore } from "@store";
import { nativeDispatch } from "@util/mobile";

import { ExperimentsContext } from "./experiments/context";
import { useOverlaysController } from "./overlay";

type UrlActionContext = {
  [key: string]: any;
  dispatch: ReturnType<typeof useDispatch>;
  error: InteractiveError;
  overlays: ReturnType<typeof useOverlaysController>;
  experiments: React.ContextType<typeof ExperimentsContext>;
  store: AppStore;
};

type UrlAction = (
  context: Partial<UrlActionContext>,
  query: Record<string, any>
) => void;

let defaultContext: UrlActionContext;

export function setUrlContext(context: UrlActionContext) {
  defaultContext = context;
}

export function updateUrlContext(context: Partial<UrlActionContext>) {
  defaultContext = { ...defaultContext, ...context };
}

const a = (action: UrlAction) => (
  query: Record<string, string>,
  context: UrlActionContext
) => action({ ...defaultContext, ...context }, query);

const goto = (url: string, appBarTitle?: string) =>
  nativeDispatch("openWebUrl", {
    url: new URL(url, window.location.origin).href,
    appBarTitle,
    appBarHidden: !appBarTitle,
  }) || Router.push(url);

// eslint-disable-next-line import/no-mutable-exports
export const actions = {
  keenly: a(() => {
    goto("/keenly");
  }),
  credit_account: {
    setup_repayment: a(() => {
      goto("/dashboard/store/setup-repayment");
    }),
    persona_flow: a(() => {
      goto("/dashboard/store/persona-flow");
    }),
    open_store: a(() => {
      goto("/dashboard/store");
    }),
    membership: a(() => {
      goto("/dashboard/credit-account/plan");
    }),
    make_a_payment: a(({ overlays }) => {
      overlays.push("credit_line/card", {});
    }),
    verify_bank_account: a(
      (
        { overlays },
        { external_bank_account_token: externalBankAccountToken }
      ) => {
        overlays.push("payments/add_payment_method", {
          type: "bank",
          externalBankAccountToken,
        });
      }
    ),
    configure_autopay: a(({ overlays }) => {
      overlays.push("credit_line/autopay", {});
    }),
    close_account: a(() => {
      Router.push("/dashboard/account/united-account-closure/ca");
    }),
  },
  debt_settlement: {
    offer: a((_, { debt_account_token: token }) => {
      goto(`/dashboard/debt-relief/start/${token}/estimate`);
    }),
    payable: a((_, { debt_account_token: token }) => {
      goto(`/dashboard/debt-relief/account/${token}`);
    }),
    home: a(() => {
      goto("/dashboard/debt-relief");
    }),
    paid: a(async ({ overlays, dispatch }, { debt_account_token: token }) => {
      await overlays.push("debt_settlement/debt_paid", { token });
      dispatch(dismiss("DEBT_PAID", token));
    }),
  },
  credit_score: {
    view: a(() => {
      goto("/dashboard/credit-score");
    }),
    start_dispute: a(() => {
      goto("/dashboard/disputes/new");
    }),
    continue_dispute: a(() => {
      goto("dashboard/disputes/new/delivery");
    }),
    coach_survey: a(({ overlays }) => {
      overlays.push("general/typeform_survey", {
        id: "Wo0kcvso",
        title: "Coach Survey",
      });
    }),
    debt_resolution_survey: a(async ({ overlays, dispatch }) => {
      await overlays.push("general/typeform_survey", {
        id: "XdkZdMi0",
        title: "Debt Resolution Survey",
        dismissKey: "DEBT_RESOLUTION_SURVEY",
      });
      dispatch(initCreditV2());
    }),
  },
  dashboard: {
    pack: a((_, { order }) => {
      goto(`/dashboard/pack/${order}`);
    }),
  },
  loan: {
    payments: a(({ overlays }) => {
      overlays.push("credit_builder_savings/card", {});
    }),
    configure_autopay: a(({ overlays }) => {
      overlays.push("lending/autopay", {
        type: web.public_.Loan.LoanType.CREDIT_BUILDER_SAVING,
      });
    }),
    withdraw_savings: a(() => {
      goto("/dashboard/credit-builder-savings/withdraw-voucher-funds");
    }),
    renew_cbl: a(() => {
      goto(
        "/dashboard/credit-builder-savings/withdraw-voucher-funds/renewal-intro"
      );
    }),
  },
  offer: {
    credit_builder_savings: a(() => {
      goto("/dashboard/offer/credit-builder-savings");
    }),
  },
  order: {
    review: a(({ overlays }, { token }) => {
      overlays.push("shopping/rate", {
        productToken: token,
      });
    }),
  },
  dashboards: {
    generic: a(({ dispatch }, { name, data }) => {
      goto(`/dashboard/cash-card?data=${data}`);
    }),
  },
  pages: {
    generic: a(({ dispatch }, { name, data }) => {
      goto(`/dashboard/cash-card/${name}?data=${data}`);
    }),
    command: a(({ overlays }, query) => {
      overlays.push("src/pages/dashboard/cash-card/_views/command", { query });
    }),
  },
  drawers: {
    generic: a(({ overlays }, { name, data }) => {
      const navigationHistory = new NavigationHistory();

      overlays
        .push("cash_card/drawer", { drawerName: name, data })
        .finally(() => navigationHistory.pop(false));
    }),
    rating: a(({ overlays }, query) => {
      const navigationHistory = new NavigationHistory();

      overlays
        .push("src/pages/dashboard/cash-card/_views/rating", { query })
        .finally(() => navigationHistory.home());
    }),
  },
  plaid: a(({ overlays }, query) => {
    overlays
      .push("payments/add_payment_method", { type: "bank" })
      .then(() => handleURL(query["finish_url"]));
  }),
  atomic_fi: a((_, query) => {
    AtomicFiAction();
  }),
  direct_deposit_bonus_tracker: a((_, query) => {
    const queryParams = new URLSearchParams(query);
    goto(
      `/dashboard/cash-card/direct_deposit_bonus_tracker?${queryParams.toString()}`
    );
  }),
  ach_transfer: a((_, query) => {
    const queryParams = new URLSearchParams(query);
    goto(`/dashboard/cash-card/ach_transfer?${queryParams.toString()}`);
  }),
  instant_transfer: a((_, query) => {
    const queryParams = new URLSearchParams(query);
    goto(`/dashboard/cash-card/instant_transfer?${queryParams}`);
  }),
  instant_transfer_result_page: a((_, query) => {
    const queryParams = new URLSearchParams(query);
    goto(`/dashboard/cash-card/instant_transfer_result_page?${queryParams}`);
  }),
  bank_transfer_ingress: a((_, query) => {
    const queryParams = new URLSearchParams(query);
    goto(
      `/dashboard/cash-card/bank_transfer_ingress?${queryParams.toString()}`
    );
  }),
  external_bank_transfer_instructions: a((_, query) => {
    const queryParams = new URLSearchParams(query);
    goto(
      `/dashboard/cash-card/external_bank_transfer_instructions?${queryParams.toString()}`
    );
  }),
  transfer_schedule: {
    create: a((_, query) => {
      const queryParams = new URLSearchParams(query);
      goto(`/dashboard/cash-card/transfer_schedule?${queryParams.toString()}`);
    }),
    update: a((_, query) => {
      const queryParams = new URLSearchParams(query);
      goto(
        `/dashboard/cash-card/update_transfer_schedule?${queryParams.toString()}`
      );
    }),
  },
  galileo: {
    activate_card: a((_, query) => {
      const queryParams = new URLSearchParams(query);
      goto(`/dashboard/cash-card/activate_card?${queryParams.toString()}`);
    }),
    reset_pin: a((_, query) => {
      const queryParams = new URLSearchParams(query);
      goto(`/dashboard/cash-card/reset_pin?${queryParams.toString()}`);
    }),
  },
};

export function handleURL(url: string, context?: Partial<UrlActionContext>) {
  const { protocol, hostname, pathname, query } = new Url(url, true);

  const protocols = [
    "kikoff:",
    "sms:",
    "clipboard:",
    "external:",
    "http:",
    "https:",
  ];
  if (process.env.NODE_ENV === "development" && !protocols.includes(protocol))
    throw new Error(
      `Unsupported protocol "${protocol}", supported protocols include: ${protocols.join(
        ", "
      )}`
    );

  switch (protocol) {
    case "kikoff:": {
      let action: unknown = actions[hostname];

      for (const scope of pathname.split("/")) {
        if (scope && action) action = action[scope];
      }

      (action as UrlAction)?.(query, context);

      break;
    }
    case "sms:": {
      window.open(url);
      break;
    }
    case "clipboard:": {
      navigator.clipboard
        .writeText(query.text)
        .then(() => toast.success("Copied to clipboard"));
      break;
    }
    case "external:": {
      window.open(query.url, "_blank");
      break;
    }
    case "http:":
    case "https:": {
      window.open(url, "_blank");
      break;
    }
    default:
      break;
  }
}
