import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
} from "react";
import { useInView } from "react-intersection-observer";
import { captureException, withScope } from "@sentry/nextjs";

import { supportsRef } from "@kikoff/client-utils/src/react";

import { track } from "./analytics";
import { Events } from "./events";

const isDev = process.env.NODE_ENV === "development";

type TrackFn = () => void;
type UseTrackImpressionOptions = {
  threshold?: number;
  afterViewedForMs?: number;
};
type EventProps = Record<
  string,
  SerializablePrimitive | SerializablePrimitive[]
>;

/**
 * Hook to precisely track impressions based on visibility of the impression subject. Uses track.impression by default, but can be overridden with a custom tracking function for backwards compatibility.
 *
 * @param nameOrTrackFn - The event name to track or a custom tracking function. Always prefer the event name over a custom tracking function.
 * @param options - Optional configuration, such as threshold and afterViewedForMs.
 *
 * @example
 * ```tsx
 * import { useTrackImpression } from '@util/track-impression';
 *
 * const Component = () => {
 *   const ref = useTrackImpression('My Event Name', { eventProp: 'value'}, { threshold: 1 });
 *
 *   return (
 *     <div ref={ref}>
 *       <h2>Header to track when in viewport</h2>
 *     </div>
 *   );
 * };
 * ```
 */
export function useTrackImpression(
  nameOrTrackFn: keyof Events | TrackFn,
  eventProps?: EventProps,
  options?: UseTrackImpressionOptions
) {
  const [eventName, trackFn] =
    typeof nameOrTrackFn === "string"
      ? [nameOrTrackFn, null]
      : [null, nameOrTrackFn];
  const { threshold = 0.6, afterViewedForMs = 2000 } = options ?? {};
  const tracked = useRef(false);
  const timeoutId = useRef<NodeJS.Timeout>();
  const trackImpression = () => {
    if (trackFn) trackFn();
    else track.impression(eventName, eventProps);
    tracked.current = true;
  };

  const { ref } = useInView({
    threshold,
    onChange: (inView) => {
      if (tracked.current) return;
      if (!inView) {
        clearTimeout(timeoutId.current);
        return;
      }

      timeoutId.current = setTimeout(trackImpression, afterViewedForMs);
    },
  });

  // Clean timer on unmount
  useEffect(() => () => clearTimeout(timeoutId.current), []);

  return ref;
}

type TrackImpressionChildrenContext = {
  ref: (node?: Element | null) => void;
};

type TrackImpressionProps = {
  children:
    | React.ReactNode
    | ((ctx: TrackImpressionChildrenContext) => React.ReactElement);
  eventProps?: EventProps;
  threshold?: number;
  afterViewedForMs?: number;
  wrap?: boolean;
} & (
  | { eventName: keyof Events; trackFn?: never }
  | { eventName?: never; trackFn: TrackFn }
);

/**
 * Component to precisely track impressions based on visibility of the impression subject. Uses track.impression by default, but can be overridden with a custom tracking function for backwards compatibility.
 *
 * @param eventName - The event name to track. Can't be used with a custom tracking function. Prefer it over a custom tracking function.
 * @param eventProps - Optional additional properties to include with the event when using eventName.
 * @param trackFn - A custom tracking function. Can't be used with an event name.
 * @param wrap - Wrap the passed component with a div. Defaults to false.
 * @param threshold - The threshold for element visibility before tracking an impression. Defaults to 0.6.
 * @param afterViewedForMs - Time to wait before tracking an impression after the element has been viewed. Defaults to 2000 ms.
 *
 * @example
 * ```tsx
 * import { TrackImpression } from '@util/track-impression';
 *
 * const Component = () => {
 *   return (
 *     <>
 *       // Child supports ref by default.
 *       <TrackImpression eventName="My Event Name" eventProps={{ key: 'value' }}>
 *         <div>You saw me now.</div>
 *       </TrackImpression>
 *
 *       // wrap = true will wrap the passed component with <div>
 *       <TrackImpression wrap trackFn={() => track.impression('My Custom Event Impression', {eventProp: 'value'})}>
 *         <CustomComponentWithoutRef />
 *       </TrackImpression>
 *     </>
 *   );
 * };
 */
export const TrackImpression = ({
  eventName,
  eventProps,
  children,
  trackFn,
  wrap,
  ...hookOptions
}: TrackImpressionProps) => {
  const ref = useTrackImpression(eventName ?? trackFn, eventProps, hookOptions);

  if (wrap) {
    return <div ref={ref}>{children}</div>;
  }

  if (typeof children === "function") {
    return children({ ref });
  }

  const ErrorDiv = ({ errorMessage }) => {
    const name = eventName ?? "Custom Function";
    const errorText = `Incorrect usage of TrackImpression component with ${name}.
          ${errorMessage}. Please refer to the TrackImpression component
          definition.`;

    withScope((scope) => {
      scope.setTag("eventName", name);
      scope.setFingerprint(["track-impression"]);
      captureException(new Error(errorText));
    });

    if (isDev) {
      return (
        <div className="text:regular++ bg:error-dugout color:error p-2">
          {errorText}
        </div>
      );
    }
    // children is wrapped in a fragment to return it directly
    // as to not impact production views
    return <>{children}</>;
  };

  if (Children.count(children) > 1) {
    const errorMessage =
      " `TrackImpression` requires a single child element. Consider using `<TrackImpression wrap>`";

    return <ErrorDiv errorMessage={errorMessage} />;
  }

  if (!isValidElement(children)) {
    const errorMessage =
      " `TrackImpression` requires either a function or valid React Element as its only child";

    return <ErrorDiv errorMessage={errorMessage} />;
  }

  if (!supportsRef(children.type)) {
    const errorMessage =
      " Child must support ref, either use forwardRef or wrap with `<TrackImpression wrap>`";

    return <ErrorDiv errorMessage={errorMessage} />;
  }

  return cloneElement(children as React.ReactElement, { ref });
};
