import "../init";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import Link from "next/link";
import Router, { useRouter } from "next/router";
import Cookie from "js-cookie";
import NProgress from "nprogress";
import { captureException, ErrorBoundary } from "@sentry/nextjs";
import { Elements } from "@stripe/react-stripe-js";

import { isIOS } from "@kikoff/client-utils/src/dom";
import { DEBUG } from "@kikoff/client-utils/src/general";
import Card from "@kikoff/components/src/v1/cards/Card";
import KLink from "@kikoff/components/src/v1/navigation/KLink";
import { DevToolProvider } from "@kikoff/hooks/src/useDevTool";
import useError from "@kikoff/hooks/src/useError";
import { ErrorProvider } from "@kikoff/hooks/src/useErrorConsumer";
import { resetId } from "@kikoff/hooks/src/useId";
import { web } from "@kikoff/proto/src/protos";
import {
  configureRpc,
  rpcEvents,
  userLeadKey,
  webRPC,
} from "@kikoff/proto/src/rpc";
import { isClient } from "@kikoff/utils/src/general";
import { http } from "@kikoff/utils/src/http";
import { maxInt } from "@kikoff/utils/src/number";
import { prefixKeys, wrapMethods } from "@kikoff/utils/src/object";

import { StyledButton } from "@component/buttons/buttons";
import CheckRouteAuth from "@component/check_route_auth";
import DownloadAppPrompt, {
  useShowDownloadAppPrompt,
} from "@component/dashboard/download_app_prompt";
import { ErrorPanel } from "@component/errors";
import HelpChat from "@component/help_chat/help_chat";
import StatusAlert from "@component/status/status_alert";
import { dismiss, selectMobileFlavor } from "@feature/page";
import { impersonationEnd } from "@feature/user";
import { ChatProvider } from "@overlay/chat";
import {
  ExperimentsProvider,
  getAllVariants,
  useExperiments,
} from "@src/experiments/context";
import useTheme from "@src/hooks/useTheme";
import { actions, handleURL, setUrlContext } from "@src/kikoff_url";
import {
  decodeOverlayStack,
  OverlayProvider,
  useOverlaysBase,
  useOverlaysController,
} from "@src/overlay";
import store from "@store";
import analytics, { track } from "@util/analytics";
import { persistLocaleOnRouteUpdate } from "@util/l10n";
import { isWebview, nativeDispatch } from "@util/mobile";
import { getService } from "@util/services";

import "nprogress/nprogress.css";
import "../global.scss";
import styles from "./_app.module.scss";

const DevOverlay = dynamic(() => import("@component/dev_overlay"));

export default function RootContextProvider(props: AppProps) {
  const { router } = props;
  const overlayData = ((query = router.query["overlay"] as string) =>
    useMemo(() => decodeOverlayStack(query), [query]))();
  useMemo(() => {
    if (!isClient) return;

    /* eslint-disable no-console */
    console.info(
      "Awesome! You're looking under the hood. Here at Kikoff, we're hiring people just like you; E-mail us at <cmVjcnVpdGluZ0BraWtvZmYuY29t>"
    );
    /* eslint-enable no-console */

    // View page events
    const eventUtms = (() => {
      // User leads
      const userLeadQueryParams = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_term",
        "utm_content",
        "utm_adid",
      ] as const;

      const { search } = window.location;
      const queryParams = new URLSearchParams(search);

      http
        .request({
          method: "get",
          url: "/api/v1/user-lead",
          params: queryParams,
          headers: { [userLeadKey]: Cookie.get(userLeadKey) },
        })
        .then((response) => {
          const userLead = response?.data || "";
          if (!userLead) return;

          Cookie.set(userLeadKey, userLead, { path: "/", expires: maxInt });
        })
        .catch((error) => console.error(error));

      if (userLeadQueryParams.every((key) => !queryParams.get(key))) return;
      const params = Object.fromEntries(
        userLeadQueryParams.map((key) => [key, queryParams.get(key)])
      ) as Record<typeof userLeadQueryParams[number], string>;
      const eventProps = Object.fromEntries(
        userLeadQueryParams.map((key) => [key, queryParams.get(key) || "EMPTY"])
      );

      const isValidReferral =
        params.utm_source === "referral" &&
        params.utm_campaign ===
          "kikoff-us-ref-ref-ref-acq-catchall-en--000000" &&
        queryParams.get("utm_content") !== null;
      if (isValidReferral)
        Cookie.set("refer", queryParams.get("utm_content"), { expires: 3 });

      return eventProps;
    })();

    analytics.identify({
      ...eventUtms,
      ...prefixKeys(getAllVariants(), "frontend experiment: "),
    });

    track("View Page", {
      ...eventUtms,
      overlays: overlayData.map(([key]) => key).join(", "),
      "detailed overlays": overlayData,
      homepage: window.location.pathname === "/",
      initial: true,
    });

    let routeInfo = {
      startViewTime: Date.now(),
      startLoadTime: null as number,
      from: window.location.pathname,
    };
    Router.events.on("routeChangeStart", (path) => {
      if (path !== window.location.pathname)
        routeInfo.startLoadTime = Date.now();

      NProgress.start();
    });
    Router.events.on("routeChangeComplete", (pathAndQuery) => {
      const [path] = pathAndQuery.split("?");
      if (routeInfo.from !== path) {
        // startLoadTime will not be set if native browser back is called
        const browserBack = !routeInfo.startLoadTime;
        const loadTime = browserBack ? 0 : Date.now() - routeInfo.startLoadTime;
        const timeSpent = Date.now() - routeInfo.startViewTime;
        track("View Page", {
          from: routeInfo.from,
          "browser back": browserBack,
          "load time": loadTime,
          "time spent (on from)": timeSpent,
          homepage: window.location.pathname === "/",
        });

        routeInfo = {
          startViewTime: Date.now(),
          startLoadTime: null,
          from: path,
        };
      }
      NProgress.done();
    });
    Router.events.on("routeChangeError", (error) => {
      track("Route Change Error", { error });
      NProgress.done();
    });
    if (DEBUG) {
      /* eslint-disable no-console */
      wrapMethods(
        Router,
        ["push", "back", "replace"],
        (method, { key }) => (...args) => {
          console.groupCollapsed(`Router.${key as string}(`, ...args, ")");
          console.trace();
          console.groupEnd();

          return method(...args);
        }
      );
      /* eslint-enable no-console */
    }
  }, []);

  const overlayDebounce = useRef<NodeJS.Timeout>(null);

  return (
    <div id="app-root">
      <StatusAlert />
      <Provider store={store}>
        <OverlayProvider
          entries={overlayData}
          onChange={(state, { withPath } = {}) => {
            const url = new URL(window.location.href);
            if (withPath) url.pathname = withPath;
            if (state.length === 0) url.searchParams.delete("overlay");
            else {
              const noNull = state.map((entry) =>
                entry.filter((item) => item != null)
              );
              url.searchParams.set(
                "overlay",
                encodeURIComponent(
                  Buffer.from(JSON.stringify(noNull)).toString("base64")
                )
              );
            }

            clearTimeout(overlayDebounce.current);

            overlayDebounce.current = setTimeout(() => {
              Router.replace(url);
            }, 100);
          }}
        >
          <GlobalHead />

          <noscript>
            <iframe
              title="gtm"
              src="https://gtm.kikoff.com/ns.html?id=GTM-KZDXSSZ"
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            />
            <img
              height="1"
              width="1"
              style={{ display: "none", visibility: "hidden" }}
              src="https://www.facebook.com/tr?id=759796597839521&ev=PageView&noscript=1"
              alt=""
            />
            <img
              src="https://s.amazon-adsystem.com/iu3?pid=6e680d11-ca05-4a10-846e-7ed2a42e2f5a&event=PageView"
              width="1"
              height="1"
              style={{ display: "none", visibility: "hidden" }}
            />
            <img
              height="1"
              width="1"
              style={{ display: "none", visibility: "hidden" }}
              src="https://q.quora.com/_/ad/354d1feeb83b42268f568d7ba66f94fc/pixel?tag=ViewContent&noscript=1"
            />
          </noscript>

          <ExperimentsProvider>
            <App {...props} />
          </ExperimentsProvider>
        </OverlayProvider>
      </Provider>
    </div>
  );
}

const stripePromise = getService("stripe").catch((e) => null);

function App({ Component, pageProps }: AppProps) {
  // Reset useId on server
  useMemo(resetId, []);

  useTheme();

  const dispatch = useDispatch();
  const error = useError();
  const overlays = useOverlaysController();
  const overlaysBase = useOverlaysBase();
  const experiments = useExperiments();

  const user = useSelector((state) => state.user);
  const showDownloadAppPrompt = useShowDownloadAppPrompt();
  const impersonating = useSelector((state) => state.page.impersonating);
  const mobileFlavor = useSelector(selectMobileFlavor());
  const featureFlags = useSelector((state) => state.page.featureFlags);

  setUrlContext({ dispatch, error, overlays, experiments, store });
  analytics.setContext({
    user: user.proto,
    store,
    featureFlags,
    impersonating,
  });

  const [showDevOverlay, setShowDevOverlay] = useState(false);

  // Use Router singleton where possible, default export from 'next/router'
  // This router hook is used because it's needed as a memo dependency outside of client context
  const router = useRouter();

  useEffect(() => {
    persistLocaleOnRouteUpdate();
  }, [router, isClient]);

  useMemo(() => {
    configureRpc({
      version: 2,
      release: process.env.KIKOFF_RELEASE,
      mobileFlavor,
    });
  }, [mobileFlavor]);

  useEffect(() => {
    rpcEvents.on("networkError", ({ err }) => {
      error.throw(err);
    });

    // Prevent input zoom on IOS
    if (isIOS) {
      document.head.querySelector("meta[name=viewport]").remove();
      document.head.innerHTML +=
        '<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />';
    }

    if (DEBUG) {
      // This is intentionally not typed as it should not be used in the
      // codebase
      // @ts-expect-error
      window.dev = {
        Router,
        React,
        actions,
        dispatch,
        handleURL,
        overlays,
        webRPC,
        dismiss(name, id) {
          return dispatch(
            dismiss(web.public_.Dismissable.Name[name] as any, id)
          );
        },
      };

      // TODO(tom): don't reference Cypress in the app. Disable if
      //   NODE_ENV=test. Need to update NODE_ENV on Vercel staging servers
      //   first.
      // See https://kikoff.atlassian.net/browse/CHRG-1473.
      if (!window?.Cypress) {
        setShowDevOverlay(true);
      }
    }

    let devtools = window.__KIKOFF_DEVTOOLS__;
    const initDevtools = async () =>
      (await import("@src/utils/injectDevtool")).init({
        overlays,
      });
    if (devtools) initDevtools();
    else {
      try {
        if ("__KIKOFF_DEVTOOLS__" in window) return;
        Object.defineProperty(window, "__KIKOFF_DEVTOOLS__", {
          get: () => devtools,
          async set(value) {
            devtools = value;
            initDevtools();
          },
        });
      } catch (e) {
        captureException(e);
      }
    }
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <DevToolProvider>
        <ErrorProvider error={error}>
          <ChatProvider>
            <KLink.PropsProvider
              schemes={{ "kikoff://": handleURL }}
              onMobileAction={(href, behavior) => {
                if (!isWebview) return false;
                if (typeof behavior === "function") behavior(href);
                else {
                  const url = href.startsWith("/")
                    ? `${window.location.origin}${href}`
                    : href;
                  const [action, arg1] = behavior.split(":");
                  const event = {
                    pushWebview: [
                      "openWebUrl",
                      {
                        url,
                        ...(arg1
                          ? { appBarTitle: arg1 }
                          : { appBarHidden: true }),
                      },
                    ],
                    openExternalBrowser: ["openExternalUrl", url],
                    openPdf: ["openPdf", url],
                  }[action] as [any, any];

                  nativeDispatch(...event);
                }
                return true;
              }}
            >
              <Card.PropsProvider ErrorBoundary={ErrorBoundary}>
                <div className={styles["general-error"]}>
                  <ErrorPanel
                    // @ts-expect-error
                    message={error.message}
                    show={error.show}
                    onClose={error.hide}
                    swipeable
                  />
                </div>
                {showDownloadAppPrompt ? (
                  <DownloadAppPrompt />
                ) : (
                  <>
                    <div id="page-root">
                      <CheckRouteAuth
                        Component={Component}
                        pageProps={pageProps}
                        router={router}
                      />
                    </div>
                    <HelpChat />
                  </>
                )}
                {showDevOverlay && <DevOverlay defaultMinimized />}
                {impersonating && (
                  <div className={styles["impersonating-banner"]}>
                    <Link href="/dashboard/home">
                      <a>You are in impersonation mode.</a>
                    </Link>
                    <StyledButton
                      sourceColor="#005eaf"
                      onClick={async () => {
                        await dispatch(impersonationEnd()).catch((e) => {
                          error.throw(e.message);
                        });
                        window.open("about:blank", "_self");
                        window.close();
                      }}
                    >
                      Finish
                    </StyledButton>
                  </div>
                )}
                {overlaysBase.render(store.getState())}
              </Card.PropsProvider>
            </KLink.PropsProvider>
          </ChatProvider>
        </ErrorProvider>
      </DevToolProvider>
    </Elements>
  );
}

function GlobalHead() {
  const isWebview = useSelector((state) => state.page.isWebview);

  return (
    <Head>
      <title>Kikoff</title>
      <meta
        name="viewport"
        content={
          isWebview
            ? "width=device-width, user-scalable=no"
            : "width=device-width, initial-scale=1"
        }
      />
      <meta httpEquiv="x-ua-compatible" content="IE=edge" />
      <meta property="og:image" content="https://kikoff.com/logo.png" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />
    </Head>
  );
}
