import * as amplitude from "@amplitude/analytics-browser";

import { isClient } from "@kikoff/utils/src/general";

if (isClient && process.env.REALLY_PRODUCTION) {
  // prettier-ignore
  // @ts-expect-error
  // eslint-disable-next-line
  !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');

  window.fbq("init", "759796597839521");
  window.fbq("track", "PageView");
}

const isStaging = process.env.NODE_ENV === "production" && !process.env.REALLY_PRODUCTION
if (isClient && isStaging) {
  window.fbq = function(type: string, ...args: any) {
    amplitude.track('Meta Event Snapshot', { type, args })
  }
}
