import Router from "next/router";
import { track } from "@amplitude/analytics-browser";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { google, web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { setAll } from "@kikoff/utils/src/object";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { AppThunk } from "@store";

import { RootState } from "../store";
import { createLoadableSelector, thunk } from "../utils";

import { selectFeatureFlag } from "./page";

const initialState = {
  currentStep: 0,
  completedSteps: 0,
  stepMap: null as web.public_.OnboardingStatus.IStep[],
  signupType: null as web.public_.OnboardingStatus.SignupType,
  completedAt: null as google.protobuf.ITimestamp | null,
  completionStatus: null as web.public_.OnboardingStatus.CompletionStatus,
  duplicateAccountDetails: null as web.public_.OnboardingStatus.IDuplicateAccountDetails | null,
  forgotDuplicateAccountLoginHint: null as web.public_.AccountRecoveryForgotLoginInquiriesHintResponse | null,
  forgotLoginInquiry: null as web.public_.AccountRecoveryForgotLoginInquiriesStartResponse | null,
  instatouchInfo: null as web.public_.OnboardingStatus.IInstatouchInfo | null,
};

export type OnboardingState = typeof initialState;

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    updateOnboardingState(
      state,
      { payload }: PayloadAction<Partial<OnboardingState>>
    ) {
      Object.assign(state, payload);
    },
    setCurrentStep(
      state,
      { payload }: PayloadAction<OnboardingState["currentStep"]>
    ) {
      state.currentStep = payload;
    },
    setCompletedSteps(
      state,
      { payload }: PayloadAction<OnboardingState["completedSteps"]>
    ) {
      state.completedSteps = payload;
    },
    setOnboardingCompletedAt(
      state,
      { payload }: PayloadAction<OnboardingState["completedAt"]>
    ) {
      state.completedAt = payload;
    },
    setCompletionStatus(
      state,
      { payload }: PayloadAction<OnboardingState["completionStatus"]>
    ) {
      state.completionStatus = payload;
    },
    setStepMap(state, { payload }: PayloadAction<OnboardingState["stepMap"]>) {
      state.stepMap = payload;
    },
    setSignupType(
      state,
      { payload }: PayloadAction<OnboardingState["signupType"]>
    ) {
      state.signupType = payload;
    },
    setForgotDuplicateAccountLoginHint(
      state,
      {
        payload,
      }: PayloadAction<OnboardingState["forgotDuplicateAccountLoginHint"]>
    ) {
      state.forgotDuplicateAccountLoginHint = payload;
    },
    setForgotLoginInquiry(
      state,
      { payload }: PayloadAction<OnboardingState["forgotLoginInquiry"]>
    ) {
      state.forgotLoginInquiry = payload;
    },
  },
});
const { actions } = onboardingSlice;
export const {
  updateOnboardingState,
  setCompletedSteps,
  setCurrentStep,
  setStepMap,
  setSignupType,
  setOnboardingCompletedAt,
  setForgotDuplicateAccountLoginHint,
  setForgotLoginInquiry,
} = actions;
export default onboardingSlice.reducer;

const stepNameDataMap = {
  name: "updateNameRequest",
  phone: "updatePhoneRequest",
  dob: "updateDobRequest",
  address: "updateAddressRequest",
  ssn: "updateSsnRequest",
} as const;

type IStep = web.public_.OnboardingStatus.IStep;
export const getStepData = (step: IStep) => step[stepNameDataMap[step.name]];

export type StepName = keyof typeof stepNameDataMap;

export type DataFromStepName<
  Name extends StepName
> = IStep[typeof stepNameDataMap[Name]];

export const selectStepData = <Name extends StepName>(stepName: Name) => (
  state: RootState
) =>
  state.onboarding.stepMap?.find(({ name }) => name === stepName)[
    stepNameDataMap[stepName]
  ] as DataFromStepName<Name>;

export const selectFirstIncompleteOnboardingStepIndex = () => (
  state: RootState
) => {
  const { stepMap } = state.onboarding;
  if (!stepMap) return 0;
  let firstIncomplete = stepMap.findIndex((step) => !step.complete);
  if (firstIncomplete === -1) firstIncomplete = stepMap.length;
  return firstIncomplete;
};

export const selectNextOnboardingRoute = () => (state: RootState) => {
  const userHasNotConfirmedEmail = !state.user.proto?.info.emailConfirmed;

  if (userHasNotConfirmedEmail) {
    return "/onboarding/verify-email";
  }

  return "/onboarding/education";
};

export const selectIsAccessibleOnboardingRoute = (route: string) => (
  state: RootState
) => {
  const rootPath = "/onboarding";
  if (!route.startsWith(rootPath)) return false;

  const subRoute = route.slice(rootPath.length);

  return subRoute === "/info";
};

export const getPersonaVerificationLink = () => {
  return webRPC.Onboarding.getPersonaVerificationLink({}).then(
    handleProtoStatus({
      SUCCESS(data) {
        return data.personaVerificationLink;
      },
      _DEFAULT: handleFailedStatus("Failed to get persona verification link."),
    })
  );
};

interface InitOnboardingOptions {
  callback?(): void;
  delay?: number;
  beforeRouteChange?(): void;
}
export const initOnboarding = ({
  callback,
  delay,
  beforeRouteChange,
}: InitOnboardingOptions = {}): AppThunk<
  Promise<web.public_.IGetOnboardingStatusResponse>
> => (dispatch, getState) => {
  function goto(route: string) {
    if (window.location.pathname === route) return;
    beforeRouteChange?.();
    Router.replace(route);
  }
  return webRPC.Onboarding.getOnboardingStatus({}).then((res) => {
    dispatch(setSignupType(res.status.signupType));
    dispatch(setOnboardingCompletedAt(res.status.completedAt));
    dispatch(actions.setCompletionStatus(res.status.completionStatus));
    const status =
      web.public_.OnboardingStatus.CompletionStatus[
        res.status.completionStatus
      ];

    if (!getState().user.proto?.info.emailConfirmed) {
      goto("/onboarding/verify-email");
      return res;
    }

    const duplicateAccountPath = "/onboarding/duplicate";
    const dontRedirect =
      status === "DUPLICATE_ACCOUNT" &&
      window.location.pathname.slice(0, duplicateAccountPath.length) ===
        duplicateAccountPath;

    if (dontRedirect) {
      return res;
    }

    if (res.status.completionStatus) {
      setTimeout(() =>
        goto(
          {
            WAITLIST_UNDERAGE: "/onboarding/waitlist/age",
            WAITLIST_STATE: "/onboarding/waitlist/state",
            ...setAll(
              [
                "RISK_CHECKING",
                "RISK_PASSED",
                "RISK_RETRY",
                "PROGRAMS_FETCHING",
                "PROGRAMS_FETCHED",
                "FROZEN_FILE",
                "ADDRESS_ISSUE",
              ],
              "/onboarding/post/identity-verification"
            ),
            RISK_FLAGGED: "/onboarding/flagged",
            DUPLICATE_ACCOUNT: "/onboarding/duplicate",
            ELIGIBLE_FOR_MANUAL_UPLOAD: "/onboarding/manual-review",
            ITIN_SUBMITTED: "/onboarding/itin-submitted",
          }[status]
        )
      );
      return res;
    }

    const stepMap = res.status.steps;
    let firstIncomplete = stepMap.findIndex((step) => !step.complete);
    if (firstIncomplete === -1) firstIncomplete = stepMap.length;

    dispatch(
      updateOnboardingState({
        stepMap: [...stepMap, { name: "review" }],
        currentStep: firstIncomplete,
        completedSteps: firstIncomplete,
        duplicateAccountDetails: res.status.duplicateAccountDetails,
        instatouchInfo: res.status.instatouchInfo,
      })
    );
    callback?.();

    const state = getState();

    if (
      res.status.instatouchInfo &&
      selectFeatureFlag("instatouch_v1")(state)
    ) {
      const status = res.status.instatouchInfo.status;

      if (
        status === web.public_.OnboardingStatus.InstatouchInfo.Status.USER_FOUND
      ) {
        goto("/onboarding/review-info");
        return res;
      }
      if (
        status === web.public_.OnboardingStatus.InstatouchInfo.Status.UNKNOWN
      ) {
        goto("/onboarding/instatouch");
        return res;
      }
    }

    if (!selectIsAccessibleOnboardingRoute(window.location.pathname)(state))
      setTimeout(() => {
        goto(selectNextOnboardingRoute()(getState()));
      }, delay);
    return res;
  });
};

export const updateDuplicateAccountDetails = (): AppThunk => (dispatch) => {
  return webRPC.Onboarding.getOnboardingStatus({}).then((res) => {
    dispatch(
      updateOnboardingState({
        duplicateAccountDetails: res.status.duplicateAccountDetails,
      })
    );
  });
};

const { SignupType } = web.public_.OnboardingStatus;

export const routeBySignupType = {
  [SignupType.CREDIT]: "/dashboard/store",
  [SignupType.FREEMIUM]: "/dashboard/credit-score",
  [SignupType.CASH_CARD]: "/dashboard/offer/cash-card-onboarding/address",
};

export function fetchForgotDuplicateAccountLoginHint() {
  return thunk((dispatch) =>
    webRPC.AccountRecoveryForgotLoginInquiriesService.hint({}).then(
      // console.info
      handleProtoStatus({
        SUCCESS(res) {
          dispatch(
            updateOnboardingState({
              forgotDuplicateAccountLoginHint: res,
            })
          );
        },
        _DEFAULT: handleFailedStatus("Failed to fetch forgot login hint."),
      })
    )
  );
}

export function fetchForgotDuplicateAccountLoginInquiry() {
  return thunk((dispatch) =>
    webRPC.AccountRecoveryForgotLoginInquiriesService.start({}).then(
      handleProtoStatus({
        SUCCESS(res) {
          dispatch(actions.setForgotLoginInquiry(res));
        },
        _DEFAULT: handleFailedStatus("Failed to fetch forgot login inquiry."),
      })
    )
  );
}

export const selectForgotDuplicateAccountLoginInquiry = createLoadableSelector(
  () => (state: RootState) => state.onboarding.forgotLoginInquiry,
  {
    loadAction: () => fetchForgotDuplicateAccountLoginInquiry(),
  }
);

export function sendPhoneCode(forgotLoginInquiryId: string) {
  return thunk(() =>
    webRPC.AccountRecoveryPhonesService.challenge({
      forgotLoginInquiryId,
    }).then(
      handleProtoStatus({
        SUCCESS(response) {
          return response;
        },
        _DEFAULT: handleFailedStatus("Failed to send phone code."),
      })
    )
  );
}

export function verifyPhoneCode(
  forgotLoginInquiryId: string,
  phoneCode: string,
  challengeToken: string
) {
  return thunk(() =>
    webRPC.AccountRecoveryPhonesService.verifyChallenge({
      forgotLoginInquiryId,
      phoneCode,
      challengeToken,
    }).then(
      handleProtoStatus({
        SUCCESS(response) {
          return response;
        },
        INCORRECT_CODE: handleFailedStatus(
          "Incorrect phone code. Please double check and try again."
        ),
        _DEFAULT: handleFailedStatus("Failed to verify phone code."),
      })
    )
  );
}

export function duplicateAccountVerifyPhoneCode(
  phone: string,
  phoneCode: string,
  forgotLoginInquiryId: string
) {
  return thunk(() =>
    webRPC.AccountRecoveryPhonesService.verifyClaim({
      phone: phone.replace(/\D/g, ""),
      phoneCode: phoneCode.replace(/\D/g, ""),
      forgotLoginInquiryId,
    }).then(
      handleProtoStatus({
        SUCCESS() {
          track("Duplicate Signup - Update Account Success", {
            type: "phone",
          });
        },
        INCORRECT_CODE: handleFailedStatus(
          "Incorrect phone code. Please double check and try again."
        ),
        _DEFAULT: handleFailedStatus("Failed to verify phone code."),
      })
    )
  );
}

export function fetchPersonaLink(forgotLoginInquiryId: string) {
  return thunk(() =>
    webRPC.AccountRecoveryPersonaService.start({
      forgotLoginInquiryId,
    }).then(
      handleProtoStatus({
        SUCCESS(res) {
          return res;
        },
        _DEFAULT: handleFailedStatus("Failed to fetch persona link."),
      })
    )
  );
}
