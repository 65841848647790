import Cookie from "js-cookie";
import * as amplitude from "@amplitude/analytics-browser";

const isClient = typeof window !== "undefined";
if (isClient) {
  amplitude.init(process.env.AMPLITUDE_API_KEY, { autocapture: {
    formInteractions: false,
    elementInteractions: false,
  } }) ;
}

const isWebview = isClient && !!window.NativeDispatch;
if (isWebview) {
  const sessionId = Cookie.get("amplitude_session_id");
  if (sessionId) {
    amplitude.setSessionId(Number(sessionId));
  }

  const deviceId = Cookie.get("amplitude_device_id");
  if (deviceId) {
    amplitude.setDeviceId(deviceId);
  }
}
