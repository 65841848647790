import EventEmitter from "@kikoff/utils/src/EventEmitter";
import { pick } from "@kikoff/utils/src/object";

import { DEBUG } from "./general";

type Properties = Record<string, any>;

export default class Analytics<
  Events extends Record<string, any>,
  Options extends { clients?: string; context?: any } = {
    clients?: never;
    context: null;
  }
> extends EventEmitter<
  [
    ["beforeTrack", [name: string, properties: Properties], boolean],
    ["anonymize"],
    [
      "provideBaseEventProperties",
      [name: string, properties: Properties],
      Properties
    ]
  ],
  Analytics<Events>
> {
  clients = {
    analytics: [] as AnalyticsClient[],
    conversion: [] as AnalyticsClient[],
    other: [] as AnalyticsClient[],
  };

  context: Options["context"];

  private analyticsMethods = [
    "track",
    "trackAll",
    "convert",
    "identify",
    "anonymize",
  ];

  constructor() {
    super();
    // Bind and attach logger to all analytics methods
    for (const method of this.analyticsMethods) {
      const ref = this[method].bind(this);
      this[method] = (...args) => {
        if (DEBUG) this.log(method, this.transformLogArgs(method, args));
        return ref(...args);
      };
    }
  }

  private log(method, args) {
    // eslint-disable-next-line no-console
    console.log(
      `%cAnalytics%c -> %c${method}%c (`,
      "color: #5af; font-weight: bold",
      "color: grey",
      "color: yellow; font-weight: bold",
      "",
      ...args,
      ")"
    );
  }

  private transformLogArgs<Method extends typeof this.analyticsMethods[number]>(
    method: Method,
    args
  ) {
    if (method === "track")
      return [
        args[0],
        { ...this.getBaseProperties(args[0], args[1]), ...args[1] },
      ];
    return args;
  }

  private getBaseProperties(eventName: string, properties?: Properties) {
    return Object.assign(
      {},
      ...this.emit("provideBaseEventProperties", [eventName, properties])
        .results
    );
  }

  private enrichedProperties(
    eventName: string,
    properties?: Properties
  ): Properties {
    return { ...this.getBaseProperties(eventName, properties), ...properties };
  }

  private trackClients(
    clients: AnalyticsClient[],
    eventName: string,
    properties?: Properties
  ) {
    for (const client of clients)
      client.track(eventName, properties, this.context);
  }

  private get allClients() {
    return [
      ...this.clients.analytics,
      ...this.clients.conversion,
      ...this.clients.other,
    ];
  }

  addClient<Name extends string>(
    type: keyof typeof this.clients,
    name: Name,
    client:
      | Client<Options["context"]>
      | Promise<Client<Options["context"]>>
      | undefined
  ) {
    if (client)
      this.clients[type].push(
        new AnalyticsClient<Name, Options["context"]>(name, client)
      );

    return (this as any) as Analytics<
      Events,
      {
        clients: // Filtering out unknown type using non-string type
        (number extends Options["clients"] ? never : Options["clients"]) | Name;
        context: Options["context"];
      }
    >;
  }

  track(eventName: string, properties?: Record<string, any>) {
    const props = this.enrichedProperties(eventName, properties);

    if (this.emit("beforeTrack", [eventName, props]).results.some(Boolean))
      return;

    this.trackClients(this.clients.analytics, eventName, props);
  }

  trackAll(eventName: string, properties?: Properties) {
    const props = this.enrichedProperties(eventName, properties);

    if (this.emit("beforeTrack", [eventName, props]).results.some(Boolean))
      return;

    this.trackClients(this.allClients, eventName, props);
  }

  convert(eventName: string, properties?: Properties) {
    this.trackClients(this.clients.conversion, eventName, properties);
    this.trackClients(
      this.clients.analytics,
      `conversion: ${eventName}`,
      this.enrichedProperties(eventName, properties)
    );
  }

  identify(properties: Properties);

  identify(id: string | null, properties?: Properties);

  identify(
    idOrProperties: string | null | Properties,
    properties?: Properties
  ) {
    for (const client of this.allClients)
      client.identify(
        typeof idOrProperties === "string" || !idOrProperties
          ? { userId: idOrProperties as string | null, properties }
          : { properties: idOrProperties },
        this.context
      );
  }

  anonymize() {
    for (const client of this.allClients)
      client.identify({ userId: null }, this.context);
    this.emit("anonymize");
  }

  copyWith(data: Partial<Pick<this, "clients" | "context">>) {
    const clone = super.clone();
    Object.assign(
      clone,
      pick(this, ["clients", "context"]),
      pick(data, ["clients", "context"])
    );

    return clone;
  }

  private withFilteredClients(
    predicate: (
      client: AnalyticsClient<Options["clients"], Options["context"]>
    ) => boolean
  ) {
    return this.copyWith({
      clients: {
        analytics: this.clients.analytics.filter(predicate),
        conversion: this.clients.conversion.filter(predicate),
        other: this.clients.other.filter(predicate),
      },
    });
  }

  only<Names extends Options["clients"]>(...clientNames: Names[]) {
    return (this.withFilteredClients(({ name }) =>
      clientNames.includes(name as Names)
    ) as any) as Analytics<
      Events,
      { clients: Names; context: Options["context"] }
    >;
  }

  except<Names extends Options["clients"]>(...clientNames: Names[]) {
    return (this.withFilteredClients(
      ({ name }) => !clientNames.includes(name as Names)
    ) as any) as Analytics<
      Events,
      {
        clients: Exclude<Options["clients"], Names>;
        context: Options["context"];
      }
    >;
  }

  withBaseProperties(
    baseProperties:
      | Properties
      | ((details: [name: string, properties: Properties]) => Properties)
  ) {
    return (this.copyWith({}).on(
      "provideBaseEventProperties",
      typeof baseProperties === "function"
        ? (baseProperties as any)
        : () => baseProperties
    ).and as unknown) as this;
  }

  setContext(context: Options["context"]) {
    this.context = context;
  }
}

interface Client<Context = null> {
  track(
    eventName: string,
    properties?: Record<string, any>,
    context?: Context
  ): void;
  identify(
    payload: {
      userId?: string | null;
      properties?: Record<string, any>;
    },
    context?: Context
  ): void;
  allowImpersonation?: boolean;
}

class AnalyticsClient<Name extends string = string, Context = null>
  implements Client<Context> {
  constructor(
    public name: Name,
    private client: Client | Promise<Client> | undefined,
    public options: { disabled?: boolean; allowImpersonation?: boolean } = {}
  ) {
    if (client == null) {
      this.options.disabled = true;
      return;
    }
    if (client instanceof Promise)
      client
        .then((client) => {
          this.options.allowImpersonation = Boolean(client.allowImpersonation);
        })
        .catch(() => {
          console.error(`Failed to load "${name}" analytics client`);
        });
    else {
      this.options.allowImpersonation = Boolean(client.allowImpersonation);
    }
  }

  private async exec<
    Method extends Exclude<keyof Client, "allowImpersonation">
  >(method: Method, ...args: Parameters<Client<Context>[Method]>) {
    if (this.options.disabled) return;

    // @ts-expect-error A spread argument must either have a tuple type or be
    // passed to a rest parameter. ts(2556)
    (await this.client)[method]?.(...args);
  }

  track(
    eventName: string,
    properties?: Record<string, any>,
    context?: Context
  ) {
    // Skip tracking if impersonating and not allowed
    if ((context as any)?.impersonating && !this.options.allowImpersonation)
      return;
    this.exec("track", eventName, properties, context);
  }

  identify(payload: Parameters<Client["identify"]>[0], context?: Context) {
    this.exec("identify", payload, context);
  }
}
