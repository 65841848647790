import differenceInDays from "date-fns/differenceInDays";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { kikoff } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import {
  handleFailedStatus,
  handleProtoStatus,
  protoDate,
} from "@kikoff/utils/src/proto";

import { useBackendExperiment } from "@src/experiments/context";
import { RootState } from "@store";

import { createLoadableSelector, thunk } from "../utils";

const initialState = {
  enrollmentInfo: null as kikoff.api.hello_privacy.GetEnrollmentInfoResponse,
};

export type HelloPrivacyState = typeof initialState;

const helloPrivacySlice = createSlice({
  name: "helloPrivacy",
  initialState,
  reducers: {
    initHelloPrivacy(
      state,
      { payload }: PayloadAction<HelloPrivacyState["enrollmentInfo"]>
    ) {
      state.enrollmentInfo = payload;
    },
  },
});

const { actions } = helloPrivacySlice;
export const { initHelloPrivacy } = actions;
export default helloPrivacySlice.reducer;

export const fetchEnrollmentInfo = () =>
  thunk((dispatch) => {
    return webRPC.HelloPrivacyService.getEnrollmentInfo({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.initHelloPrivacy(data));
          return data;
        },
        _DEFAULT: () => handleFailedStatus("Failed to get enrollment info"),
      })
    );
  });

export const selectHelloPrivacyEnrollmentInfo = createLoadableSelector(
  () => (state: RootState) => state.helloPrivacy.enrollmentInfo?.enrollmentInfo,
  {
    loadAction: fetchEnrollmentInfo,
  }
);

export const selectHelloPrivacyEnrollment = createLoadableSelector(
  () => (state: RootState) => state.helloPrivacy.enrollmentInfo,
  {
    loadAction: fetchEnrollmentInfo,
  }
);

export const useAutoUnenrollEnabled = () => {
  const autoUnenrollExperimentVariant = useBackendExperiment(
    "helloPrivacyOnDiscoverMore"
  );
  return ["unenroll_only", "discover_more_and_unenroll"].includes(
    autoUnenrollExperimentVariant
  );
};

export namespace HelloPrivacy {
  export type EnrollmentInfo = kikoff.api.hello_privacy.IEnrollmentInfo;
  export type TabType =
    | "REMOVED"
    | "PENDING"
    | "EXPOSED"
    | "DATA_REMOVED"
    | "EXPOSURES";

  export type StatusType =
    | keyof typeof kikoff.api.hello_privacy.GetScanRecordsRequest.RecordType
    | "PROCESSING";

  export type RecordType = keyof typeof kikoff.api.hello_privacy.GetScanRecordsRequest.RecordType;

  export const daysSinceLastScan = (enrollmentInfo: EnrollmentInfo) => {
    const today = new Date();

    if (!enrollmentInfo?.lastScanAt) return null;
    const lastScanAt = protoDate(enrollmentInfo.lastScanAt);
    return differenceInDays(today, lastScanAt);
  };

  export const daysUntilNextScan = (enrollmentInfo: EnrollmentInfo) => {
    const today = new Date();

    if (!enrollmentInfo?.nextScanAt) return null;
    const nextScanAt = protoDate(enrollmentInfo.nextScanAt);
    return differenceInDays(nextScanAt, today);
  };

  export const daysSinceEnrollment = (
    enrollmentInfo: EnrollmentInfo
  ): number | null => {
    const today = new Date();

    if (!enrollmentInfo?.enrolledAt) return null;
    return differenceInDays(today, protoDate(enrollmentInfo.enrolledAt));
  };

  export const enrolledInHelloPrivacy = (enrollmentInfo: EnrollmentInfo) => {
    if (!enrollmentInfo?.enrollmentStatus) return null;
    return (
      enrollmentInfo.enrollmentStatus ===
      kikoff.api.hello_privacy.EnrollmentInfo.Status.ACTIVE
    );
  };
}
