import { isClient } from "@kikoff/utils/src/general";

import { getQueryParam } from "./dom";

export const DEBUG =
  isClient &&
  (process.env.NODE_ENV !== "production" ||
    getQueryParam.presence("DEBUG_PRODUCTION"));

export const CANARY_TRAFFIC =
  isClient && DEBUG && getQueryParam.presence("CANARY_TRAFFIC");
