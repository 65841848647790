import { useMemo } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import Router from "next/router";
import { addDays, addWeeks, getDay, startOfMonth } from "date-fns";
import { capitalize, maxBy, minBy, orderBy, sum } from "lodash-es";
import { Tuple } from "record-tuple";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { google, web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { dedupeBy } from "@kikoff/utils/src/array";
import { invertResult, memo } from "@kikoff/utils/src/function";
import { serverNow } from "@kikoff/utils/src/number";
import { transformEntries, UObject } from "@kikoff/utils/src/object";
import {
  handleFailedStatus,
  handleProtoStatus,
  protoDate,
  protoTime,
} from "@kikoff/utils/src/proto";
import { conjunctionList, format } from "@kikoff/utils/src/string";
import Table from "@kikoff/utils/src/table";

import { useBackendExperiment } from "@src/experiments/context";
import { Popup, PopupType } from "@src/hooks/usePopups";
import { RootState } from "@store";
import { track } from "@util/analytics";
import { nativeDispatch } from "@util/mobile";

import { createLoadableSelector, thunk } from "../utils";

import { Bureau } from "./credit";
import { dismiss, selectDismissal } from "./page";
import { fetchRecommendations } from "./recommendations";
import {
  fetchPreviewChangeSubscriptionPlan,
  selectChangeSubscriptionPlanPreview,
  selectIsPremiumOrUltimate,
  updateOrders,
} from "./shopping";
import { fetchUser } from "./user";

const creditDisputesContexts = Object.keys(
  web.public_.DisputeContext
) as CreditDisputes.Context[];

const defaultCreditDisputesContext: CreditDisputes.Context =
  "SINGLE_BUREAU_EFX";

type ContextDetails = typeof initialContextDetails;
const initialContextDetails = {
  disputableTradelineIds: null as CreditDisputeItem.TradelineId[],
  checkedByTradelineId: {} as Record<CreditDisputeItem.TradelineId, boolean>,
  nextDisputableTradelineIds: null as CreditDisputeItem.TradelineId[],

  maxItems: 5,
  priceCents: 0,
  nextAllowedAt: null as google.protobuf.ITimestamp,
};

const ineligibleContextDetails: Partial<ContextDetails> = {
  checkedByTradelineId: {} as Record<CreditDisputeItem.TradelineId, boolean>,
  nextDisputableTradelineIds: [] as CreditDisputeItem.TradelineId[],

  maxItems: 0,
  priceCents: 0,
  nextAllowedAt: { seconds: Infinity } as google.protobuf.ITimestamp,
};

const initialState = {
  // Shared state
  eligibilityByContext: null as CreditDisputes.Eligibility.ByContext,
  // TODO(tu-refactor): check this on upsells and stuff before calling
  // GetDisputeContextDetails for 1b
  disputableItemsAvailable: null as boolean,
  newDisputeItemsAvailable: null as boolean,
  drawerToken: null as string,
  show3b: null as boolean,
  reasonExplanationByReasonByCategory: null as CreditDisputes.ReasonExplanation.ByReason.ByCategory,

  detailsByContext: Object.fromEntries(
    creditDisputesContexts.map((context) => [context, initialContextDetails])
  ) as Record<CreditDisputes.Context, ContextDetails>,

  context: defaultCreditDisputesContext as CreditDisputes.Context,

  itemByItemId: {} as Record<CreditDisputeItem.ItemId, CreditDisputeItem>,
  itemIdByBureauByTradelineId: {} as ItemIdByBureau.ByTradelineId,
  tradelineIdByAccountId: {} as Record<
    CreditDisputeItem.AccountId,
    CreditDisputeItem.TradelineId
  >,
  contextByTradelineId: {} as Record<
    CreditDisputeItem.TradelineId,
    CreditDisputes.Context
  >,

  // Lists
  disputedTradelineIds: null as CreditDisputeItem.TradelineId[],

  // Selection
  reasonsByTradelineId: {} as Record<
    CreditDisputeItem.TradelineId,
    web.public_.CreditDisputeItem.Reason[]
  >,
  customReasonsByTradelineId: {} as Record<
    CreditDisputeItem.TradelineId,
    string
  >,

  disputeLettersLoaded: false,
  disputeByLetterToken: {} as CreditDispute.ByLetterToken,
  letterTokenByBureauBySubmissionToken: {} as CreditDispute.LetterToken.ByBureau.BySubmissionToken,
  contextBySubmissionToken: {} as Record<
    CreditDispute.SubmissionToken,
    CreditDisputes.Context
  >,
  pendingSubmissionToken: null as CreditDispute.SubmissionToken,
  sentSubmissionTokens: [] as CreditDispute.SubmissionToken[],
  newResponseSubmissionToken: null as CreditDispute.SubmissionToken,
  // Manual mail is only available with 1B
  readyToMailLetterToken: null as CreditDispute.LetterToken,
  hasUnviewedDispute: false,

  pdfLinkByLetterToken: {} as Record<CreditDispute.LetterToken, string>,
};

export type CreditDisputesState = typeof initialState;

export type DisputeMethod =
  | "efax"
  | "mail"
  | "premium"
  | "ultimate"
  | "premium-reactivation"
  | "basic-reactivation";

const creditDisputesSlice = createSlice({
  name: "creditDisputes",
  initialState,
  reducers: {
    setDisputableItems(
      state,
      {
        payload: { context, disputableItems, nextDisputableItems = [] },
      }: PayloadAction<{
        context: CreditDisputes.Context;
        disputableItems: CreditDisputeItem[];
        nextDisputableItems?: CreditDisputeItem[];
      }>
    ) {
      state.detailsByContext[context].disputableTradelineIds = dedupeBy(
        disputableItems,
        "tradelineId"
      ).map(({ tradelineId }) => tradelineId);
      state.detailsByContext[context].nextDisputableTradelineIds = dedupeBy(
        nextDisputableItems,
        "tradelineId"
      ).map(({ tradelineId }) => tradelineId);

      const items = dedupeBy(
        [...disputableItems, ...nextDisputableItems],
        "itemId"
      );

      Object.assign(
        state.itemByItemId,
        Object.fromEntries(items.map((item) => [item.itemId, item]))
      );
      Object.assign(
        state.itemIdByBureauByTradelineId,
        ItemIdByBureau.ByTradelineId.fromItemList(items)
      );
      Object.assign(
        state.tradelineIdByAccountId,
        Object.fromEntries(
          items.map(({ tradelineId, accountId }) => [accountId, tradelineId])
        )
      );
      Object.assign(
        state.contextByTradelineId,
        Object.fromEntries(items.map((item) => [item.tradelineId, context]))
      );
    },
    setCheckedDisputableItems(
      state,
      {
        payload: { context, checkedItems },
      }: PayloadAction<{
        context: CreditDisputes.Context;
        checkedItems: ContextDetails["checkedByTradelineId"];
      }>
    ) {
      state.detailsByContext[context].checkedByTradelineId = checkedItems;
    },
    updateCheckedDisputableItems(
      state,
      { payload }: PayloadAction<ContextDetails["checkedByTradelineId"]>
    ) {
      Object.assign(
        state.detailsByContext[state.context].checkedByTradelineId,
        payload
      );
    },
    updateDisputableItemReasons(
      state,
      { payload }: PayloadAction<CreditDisputesState["reasonsByTradelineId"]>
    ) {
      Object.assign(state.reasonsByTradelineId, payload);
    },
    updateDisputableItemCustomReason(
      state,
      {
        payload,
      }: PayloadAction<CreditDisputesState["customReasonsByTradelineId"]>
    ) {
      Object.assign(state.customReasonsByTradelineId, payload);
    },
    setDisputes(state, { payload }: PayloadAction<CreditDispute[]>) {
      state.disputeLettersLoaded = true;

      const disputes = orderBy(
        payload,
        ({ sentAt }) => sentAt?.seconds,
        "desc"
      ).map((disputeLetter) => {
        const disputedItems = disputeLetter.disputedItems.map((disputeItem) => {
          const isPreviousInfo =
            CreditDisputeItem.hasPreviousLetter(
              disputeItem,
              disputeLetter.letterToken
            ) && disputeLetter.sentAt;

          return isPreviousInfo
            ? CreditDisputeItem.withPreviousInfo(
                disputeItem,
                disputeLetter.letterToken
              )
            : disputeItem;
        });

        return { ...disputeLetter, disputedItems };
      });

      state.disputeByLetterToken = Object.fromEntries(
        disputes.map((dispute) => [dispute.letterToken, dispute])
      );
      state.letterTokenByBureauBySubmissionToken = CreditDispute.LetterToken.ByBureau.BySubmissionToken.fromDisputeList(
        disputes
      );

      state.pendingSubmissionToken =
        disputes.find(CreditDispute.isPending)?.submissionToken || null;
      state.newResponseSubmissionToken =
        disputes.find(CreditDispute.hasNewResponse)?.submissionToken || null;
      state.readyToMailLetterToken =
        disputes.find(CreditDispute.isReadyToMail)?.letterToken || null;
      state.sentSubmissionTokens = [
        ...new Set(
          disputes
            .filter(invertResult(CreditDispute.isPending))
            .map(({ submissionToken }) => submissionToken)
        ),
      ];
      state.hasUnviewedDispute = disputes.some(({ viewed }) => !viewed);

      const disputedItems = dedupeBy(
        payload.flatMap(({ disputedItems }) => disputedItems),
        "itemId"
      );
      state.disputedTradelineIds = [
        ...new Set(disputedItems.map(({ tradelineId }) => tradelineId)),
      ];
      Object.assign(
        state.itemIdByBureauByTradelineId,
        ItemIdByBureau.ByTradelineId.fromItemList(disputedItems)
      );
      Object.assign(
        state.itemByItemId,
        Object.fromEntries(disputedItems.map((item) => [item.itemId, item]))
      );
    },
    updateDetailsByContext(
      state,
      {
        payload,
      }: PayloadAction<Partial<CreditDisputesState["detailsByContext"]>>
    ) {
      for (const [context, details] of Object.entries(payload)) {
        Object.assign(state.detailsByContext[context], details);
      }
    },
    updateContextBySubmissionToken(
      state,
      {
        payload,
      }: PayloadAction<CreditDisputesState["contextBySubmissionToken"]>
    ) {
      Object.assign(state.contextBySubmissionToken, payload);
    },
    updatePdfLinks(
      state,
      { payload }: PayloadAction<CreditDisputesState["pdfLinkByLetterToken"]>
    ) {
      Object.assign(state.pdfLinkByLetterToken, payload);
    },
    setDisputesContext(
      state,
      { payload }: PayloadAction<CreditDisputesState["context"]>
    ) {
      state.context = payload;
    },
    setEligibilityByContext(
      state,
      { payload }: PayloadAction<CreditDisputesState["eligibilityByContext"]>
    ) {
      state.eligibilityByContext = payload;
    },
    setDisputableItemsAvailable(
      state,
      {
        payload,
      }: PayloadAction<CreditDisputesState["disputableItemsAvailable"]>
    ) {
      state.disputableItemsAvailable = payload;
    },
    setNewDisputeItemsAvailable(
      state,
      {
        payload,
      }: PayloadAction<CreditDisputesState["newDisputeItemsAvailable"]>
    ) {
      state.newDisputeItemsAvailable = payload;
    },
    setDrawerToken(
      state,
      { payload }: PayloadAction<CreditDisputesState["drawerToken"]>
    ) {
      state.drawerToken = payload;
    },
    setShow3b(
      state,
      { payload }: PayloadAction<CreditDisputesState["show3b"]>
    ) {
      state.show3b = payload;
    },
    setReasonExplanationByReasonByCategory(
      state,
      {
        payload,
      }: PayloadAction<
        CreditDisputesState["reasonExplanationByReasonByCategory"]
      >
    ) {
      state.reasonExplanationByReasonByCategory = payload;
    },
  },
});

export const updateDisputableItemReasons = (
  reasonsByTradelineId: CreditDisputesState["reasonsByTradelineId"]
) =>
  thunk((dispatch) => {
    dispatch(actions.updateDisputableItemReasons(reasonsByTradelineId));
    for (const [tradelineId, reasons] of Object.entries(reasonsByTradelineId)) {
      if (reasons.length === 0) {
        dispatch(updateCheckedDisputableItems({ [tradelineId]: false }));
      }
    }
  });

const { actions } = creditDisputesSlice;
export const {
  setCheckedDisputableItems,
  updateCheckedDisputableItems,
  updateDisputableItemCustomReason,
} = actions;

export default creditDisputesSlice.reducer;

export const groupLabels: Record<
  keyof typeof web.public_.CreditDisputeItem.Category,
  string
> = {
  CHARGE_OFF: "Charge-offs",
  COLLECTION: "Collections",
  INQUIRY: "Inquiries",
  LATE_PAYMENT: "Late payments",
  BANKRUPTCY: "Bankruptcies",
  PUBLIC_RECORDS: "Public records",
  PERSONAL_INFO: "Personal info",
  ACCOUNT: "Accounts",
};

export const itemLabels: Record<
  keyof typeof web.public_.CreditDisputeItem.Category,
  string
> = {
  CHARGE_OFF: "Charge-off",
  COLLECTION: "Collection",
  INQUIRY: "Inquiry",
  LATE_PAYMENT: "Late payment",
  BANKRUPTCY: "Bankruptcy",
  PUBLIC_RECORDS: "Public record",
  PERSONAL_INFO: "Personal info",
  ACCOUNT: "Account",
};

export const disputeStatusLabels: Record<
  keyof typeof web.public_.Dispute.Status,
  string
> = {
  PENDING: "Pending",
  SENT: "Sent",
  REVIEWED: "Completed",
  IN_REVIEW: "In review",
  READY_TO_MAIL: "Ready to mail",
  MAILED_BY_USER: "Mailed",
  CANCELLED_BY_BUREAU: "Cancelled by bureau",
  CANCELLED_BY_USER: "Cancelled by user",
  PARTIALLY_REVIEWED: "Partially reviewed",
};

export const SurveyOption =
  web.public_.SaveDisputeSurveyResponseRequest.SurveyOption;

export const surveyLabels: Partial<
  Record<keyof typeof SurveyOption, string>
> = {
  NONE: "",
  ALL_ITEMS_REMOVED: "All items were removed",
  ONE_ITEM_REMOVED: "At least one item was removed",
  NO_ITEM_REMOVED: "All items were verified as accurate and none were removed",
  FRIVOLOUS_LETTER: "Bureau marked letter as frivolous",
  MORE_INFO_REQUEST: "Bureau asked for more information",
  OTHER: "Other",
  ITEM_NOT_REMOVED: "Item not removed",
  UNAUTHORIZED_LETTER_SENT:
    "The bureau believes the letter wasn't sent by you or an authorized party",
};

export const selectDisputesContextDetails = (
  _context?: CreditDisputes.Context
) => (state: RootState) => {
  const context = _context ?? state.creditDisputes.context;

  const details = state.creditDisputes.detailsByContext[context];

  return selectDisputesEligibility(context)(state)
    ? details
    : { details, ...ineligibleContextDetails };
};

export const selectShow3bDisputes = () => (state: RootState) =>
  state.creditDisputes.show3b;

export const selectDisputesContext = Object.assign(
  () => (state: RootState) => state.creditDisputes.context,
  {
    byType: (type: CreditDisputes.Type) => (state: RootState) => {
      if (type === "ONLINE") {
        return "TRANSUNION_INSTANT";
      }
      return selectDisputesEligibility("TRIBUREAU")(state)
        ? "TRIBUREAU"
        : "SINGLE_BUREAU_EFX";
    },
  }
);

export const selectDisputableTradelineIds = Object.assign(
  (context: CreditDisputes.Context) => (state: RootState) =>
    state.creditDisputes.detailsByContext[context].disputableTradelineIds,
  {
    forAll: () => (state: RootState) =>
      CreditDisputes.contexts.flatMap(
        (context) => selectDisputableTradelineIds(context)(state) ?? []
      ),
  }
);

export const selectIsTradelineDisputable = (
  tradelineId: CreditDisputeItem.TradelineId,
  context: CreditDisputes.Context = "SINGLE_BUREAU_EFX"
) => (state: RootState) =>
  state.creditDisputes.detailsByContext[
    context
  ].disputableTradelineIds?.includes(tradelineId);

export const selectHasDisputableItems = createLoadableSelector(
  (contexts: CreditDisputes.Context[] = CreditDisputes.contexts) => (state) =>
    contexts.some(
      (context) =>
        state.creditDisputes.detailsByContext[context].disputableTradelineIds
          ?.length
    ),
  {
    loadAction: (
      contexts: CreditDisputes.Context[] = CreditDisputes.contexts
    ) => (dispatch) =>
      Promise.all(
        contexts.map((context) => dispatch(fetchCreditDisputableItems(context)))
      ),
    selectLoaded: (
      contexts: CreditDisputes.Context[] = CreditDisputes.contexts
    ) => (state) =>
      contexts.every(
        (context) =>
          state.creditDisputes.detailsByContext[context].disputableTradelineIds
      ),
  }
);

export const selectDisputableItemCount = Object.assign(
  createLoadableSelector(
    (context?: CreditDisputes.Context) => (state: RootState) =>
      selectDisputesContextDetails(context)(state).disputableTradelineIds
        ?.length,
    {
      loadAction: (context?: CreditDisputes.Context) => (dispatch, getState) =>
        dispatch(
          fetchCreditDisputableItems(
            context ?? getState().creditDisputes.context
          )
        ),
    }
  ),
  {
    forAll: () => (state: RootState) =>
      sum(
        CreditDisputes.contexts.map(
          (context) => selectDisputableItemCount(context)(state) ?? 0
        )
      ),
  }
);
export const selectNextDisputableItems = createLoadableSelector(
  (context?: CreditDisputes.Context) => (state: RootState) =>
    selectDisputesContextDetails(context)(state).nextDisputableTradelineIds,
  {
    loadAction: (context?: CreditDisputes.Context) => (dispatch, getState) =>
      dispatch(
        fetchCreditDisputableItems(context ?? getState().creditDisputes.context)
      ),
  }
);

export const selectNextDisputableItemsCount = createLoadableSelector(
  (context?: CreditDisputes.Context) => (state: RootState) =>
    selectNextDisputableItems(context)(state)?.length,
  {
    dependsOn: [selectNextDisputableItems],
  }
);

export const selectDisputeItemIdByBureau = createLoadableSelector(
  (tradelineId: CreditDisputeItem.TradelineId) => (state: RootState) =>
    state.creditDisputes.itemIdByBureauByTradelineId[tradelineId],
  {
    loadAction: (context?: CreditDisputes.Context) => (dispatch, getState) =>
      dispatch(
        fetchCreditDisputableItems(context ?? getState().creditDisputes.context)
      ),
  }
);

export const selectDisputeItemByBureau = (
  tradelineId: CreditDisputeItem.TradelineId
) => (state: RootState) =>
  transformEntries(
    selectDisputeItemIdByBureau(tradelineId)(state),
    ([bureau, itemId]) => [bureau, state.creditDisputes.itemByItemId[itemId]]
  );

export const selectDefaultDisputableItems = createLoadableSelector(
  ({
    context,
    nextDisputable = false,
  }: { context?: CreditDisputes.Context; nextDisputable?: boolean } = {}) => (
    state
  ) =>
    state.creditDisputes.detailsByContext[
      context ?? state.creditDisputes.context
    ][
      nextDisputable ? "nextDisputableTradelineIds" : "disputableTradelineIds"
    ]?.map(
      (tradelineId) =>
        state.creditDisputes.itemByItemId[
          ItemIdByBureau.defaultItemId(
            selectDisputeItemIdByBureau(tradelineId)(state)
          )
        ]
    ) ?? [],
  { dependsOn: [selectDisputeItemIdByBureau] }
);

export const selectDisputeItemGroups = memo(
  (items: CreditDisputeItem[]) => (_state: RootState) => {
    const deduped = dedupeBy(items, "tradelineId");

    return Object.entries(
      deduped.groupBy(
        (item) =>
          groupLabels[
            web.public_.CreditDisputeItem.Category[item.category]
          ] as string
      )
    );
  },
  {
    by: ([items]) => [
      items.length === 0
        ? // Memoize all empty arrays together
          Tuple()
        : items,
    ],
  }
);

export const selectInReviewDisputeItemGroups = () => (state: RootState) =>
  selectDisputeItemGroups(
    Object.values(
      state.creditDisputes.disputeByLetterToken
    ).flatMap(({ disputedItems, sentAt, reviewedAt }) =>
      sentAt && !reviewedAt ? disputedItems : []
    )
  )(state);

export const selectMaxDisputeItems = Object.assign(
  (context?: CreditDisputes.Context) => (state: RootState) =>
    selectDisputesContextDetails(context)(state).maxItems,
  {
    forAll: () => (state: RootState) =>
      Math.max(
        ...CreditDisputes.contexts.map((context) =>
          selectMaxDisputeItems(context)(state)
        )
      ),
  }
);

export const selectDisputesPriceCents = () => (state: RootState) =>
  selectDisputesContextDetails()(state).priceCents;

export const selectCheckedByTradelineId = () => (state: RootState) =>
  selectDisputesContextDetails()(state).checkedByTradelineId;

export const selectCheckedCount = () => (state: RootState) =>
  sum([
    0,
    ...Object.values(
      selectDisputesContextDetails()(state).checkedByTradelineId
    ),
  ]);

export const selectDisputeItem = Object.assign(
  (itemId: CreditDisputeItem.ItemId) => (state: RootState) =>
    state.creditDisputes.itemByItemId[itemId],
  {
    default: (tribureauId: string) => (state: RootState) =>
      state.creditDisputes.itemByItemId[
        ItemIdByBureau.defaultItemId(
          selectDisputeItemIdByBureau(tribureauId)(state)
        )
      ],
  }
);

export const selectDisputableItemState = (tradelineId: string) => (
  state: RootState
) => {
  return {
    checked: CreditDisputes.contexts.some(
      (context) =>
        !!state.creditDisputes.detailsByContext[context].checkedByTradelineId[
          tradelineId
        ]
    ),
    reasons: state.creditDisputes.reasonsByTradelineId[tradelineId] ?? [],
    customReason: state.creditDisputes.customReasonsByTradelineId[tradelineId],
  };
};

export const selectDisputableItemContext = Object.assign(
  (tradelineId: CreditDisputeItem.TradelineId) => (state: RootState) =>
    state.creditDisputes.contextByTradelineId[tradelineId],
  {
    byTradelineId: () => (state: RootState) =>
      state.creditDisputes.contextByTradelineId,
  }
);

export const selectLatestDispute = () => (state: RootState) =>
  Object.values(state.creditDisputes.disputeByLetterToken).find(
    ({ sentAt }) => sentAt
  );

export const selectDisputesLoaded = () => (state: RootState) =>
  state.creditDisputes.disputeLettersLoaded;

export const selectDispute = createLoadableSelector(
  (letterToken: CreditDispute.LetterToken) => (state: RootState) =>
    state.creditDisputes.disputeByLetterToken[letterToken],
  {
    loadAction: () => fetchCreditDisputes(),
    selectLoaded: selectDisputesLoaded,
  }
);

export const selectDisputeByLetterToken = () => (state: RootState) =>
  state.creditDisputes.disputeByLetterToken;

export const selectDisputeSubmission = Object.assign(
  createLoadableSelector(
    (submissionToken: CreditDispute.SubmissionToken) => (state) =>
      transformEntries(
        state.creditDisputes.letterTokenByBureauBySubmissionToken[
          submissionToken
        ] || ({} as never),
        ([bureau, letterToken]) => [
          bureau,
          state.creditDisputes.disputeByLetterToken[letterToken],
        ]
      ),
    {
      dependsOn: [selectDispute],
    }
  ),
  {
    pending: Object.assign(
      () => (state: RootState) =>
        selectDisputeSubmission(selectDisputeSubmission.pending.token()(state))(
          state
        ),
      {
        token: (type?: CreditDisputes.Type) => (state: RootState) => {
          const pendingSubmissionToken =
            state.creditDisputes.pendingSubmissionToken;
          const isPendingSubmissionTokenTransunion = selectDisputeSubmission.isTransunion(
            pendingSubmissionToken
          )(state);

          if (!type) {
            return pendingSubmissionToken;
          }

          if (type === "ONLINE") {
            if (isPendingSubmissionTokenTransunion) {
              return pendingSubmissionToken;
            }
          } else if (!isPendingSubmissionTokenTransunion) {
            return pendingSubmissionToken;
          }

          return null;
        },
      }
    ),
    is3b: (submissionToken: CreditDispute.SubmissionToken) => (
      state: RootState
    ) => {
      const submission =
        state.creditDisputes.letterTokenByBureauBySubmissionToken[
          submissionToken
        ];

      return (
        !!submission &&
        state.creditDisputes.contextBySubmissionToken[submissionToken] ===
          "TRIBUREAU"
      );
    },
    isTransunion: (submissionToken: CreditDispute.SubmissionToken) => (
      state: RootState
    ) => {
      const submission =
        state.creditDisputes.letterTokenByBureauBySubmissionToken[
          submissionToken
        ];

      return (
        !!submission &&
        state.creditDisputes.contextBySubmissionToken[submissionToken] ===
          "TRANSUNION_INSTANT"
      );
    },
    context: (submissionToken: CreditDispute.SubmissionToken) => (
      state: RootState
    ): CreditDisputes.Context =>
      state.creditDisputes.contextBySubmissionToken[submissionToken],
  }
);

export const selectReadyToMailDispute = createLoadableSelector(
  () => (state) =>
    state.creditDisputes.disputeByLetterToken[
      state.creditDisputes.readyToMailLetterToken
    ],
  {
    dependsOn: [selectDispute],
  }
);

export const selectSentSubmissionTokens = () => (state: RootState) =>
  state.creditDisputes.sentSubmissionTokens;

export const selectDefaultSentDisputes = () => (state: RootState) =>
  state.creditDisputes.sentSubmissionTokens.map(
    (token) =>
      state.creditDisputes.disputeByLetterToken[
        CreditDispute.LetterToken.ByBureau.defaultLetterToken(
          state.creditDisputes.letterTokenByBureauBySubmissionToken[token]
        )
      ]
  );

export const selectHasUnviewedDisputes = () => (state: RootState) =>
  state.creditDisputes.hasUnviewedDispute;

export const selectHasSentDisputes = Object.assign(
  () => (state: RootState) =>
    state.creditDisputes.sentSubmissionTokens.length > 0,
  {
    retain: () => (state: RootState) => {
      const loaded = selectDisputesLoaded()(state);
      const has = selectHasSentDisputes()(state);
      return useMemo(() => has, [loaded]);
    },
    any3b: () => (state: RootState) =>
      state.creditDisputes.sentSubmissionTokens.some((submissionToken) =>
        selectDisputeSubmission.is3b(submissionToken)(state)
      ),
  }
);

export const selectNewDisputeAllowedAt = Object.assign(
  (context?: CreditDisputes.Context) => (state: RootState) =>
    (selectDisputesContextDetails(context)(state).nextAllowedAt?.seconds || 0) *
    1000,
  {
    forAll: () => (state: RootState) =>
      Math.min(
        ...CreditDisputes.contexts.map((context) =>
          selectNewDisputeAllowedAt(context)(state)
        )
      ),
  }
);

export const selectNewDisputeAllowed = Object.assign(
  (context?: CreditDisputes.Context) => (state: RootState) =>
    +selectNewDisputeAllowedAt(context)(state) <= serverNow() &&
    selectMaxDisputeItems(context)(state) > 0,
  {
    forAll: () => (state: RootState) =>
      CreditDisputes.contexts.some((context) =>
        selectNewDisputeAllowed(context)(state)
      ),
  }
);

export const selectSendWithAllowed = (context?: CreditDisputes.Context) => (
  state: RootState
) => {
  const today = new Date();
  const readyToMailSentAt = protoDate(
    selectReadyToMailDispute()(state)?.sentAt
  );

  return (
    (today.getMonth() === readyToMailSentAt.getMonth() &&
      today.getFullYear() === readyToMailSentAt.getFullYear()) ||
    selectNewDisputeAllowed(context)(state)
  );
};

export const selectAllowMultiroundDispute = Object.assign(
  () => (state: RootState) => selectNewDisputeAllowed()(state),
  {
    forItem: (item: CreditDisputeItem) => (state: RootState) =>
      selectAllowMultiroundDispute()(state) &&
      selectIsTradelineDisputable(item.tradelineId)(state),
  }
);

export const selectDisputeLetterPdfLink = createLoadableSelector(
  (letterToken: CreditDispute.LetterToken) => (state) =>
    state.creditDisputes.pdfLinkByLetterToken[letterToken],
  {
    loadAction: (letterToken) => fetchDisputeLetterPdfLink(letterToken),
  }
);

export const selectTradelineId = {
  byAccountId: (accountId: CreditDisputeItem.AccountId) => (state: RootState) =>
    state.creditDisputes.tradelineIdByAccountId[accountId],
};

export const selectNewDisputeResponse = Object.assign(
  () => (state: RootState) => {
    const token = selectNewDisputeResponse.submissionToken()(state);
    if (!token) return;
    return selectDisputeSubmission(token)(state);
  },
  {
    submissionToken: () => (state: RootState) =>
      state.creditDisputes.newResponseSubmissionToken,
  }
);

export const selectFirstRedisputedLetter = (
  letterToken: CreditDispute.LetterToken
) => (state: RootState) => {
  const dispute = selectDispute(letterToken)(state);
  if (!dispute) return;

  return minBy(
    dispute.disputedItems
      // Exclude items that are current to this letter
      .filter(
        (item) =>
          selectDisputeItem(item.itemId)(state).letterToken !== letterToken
      )
      .flatMap((item) => [
        ...item.previousDisputeInfos
          .slice(0, -1)
          .map(({ letterToken }) => letterToken),
        item.letterToken,
      ])
      .map((letterToken) => selectDispute(letterToken)(state))
      // Exclude previous letters
      .filter(
        (disputeLetter) =>
          disputeLetter?.sentAt?.seconds >= dispute.sentAt?.seconds
      ),
    (disputeLetter) => disputeLetter?.sentAt?.seconds
  );
};

export const selectPremiumDisputesBenefits = () => (state: RootState) => {
  const preview = selectChangeSubscriptionPlanPreview("premium")(state);
  return state.creditDisputes.show3b == null
    ? []
    : state.creditDisputes.show3b
    ? [
        <>
          Find more potential errors with access to data from two more credit
          bureaus
        </>,
        <>Get credit report from all 3 bureaus every month</>,
        <>
          A higher credit line{" "}
          {format.money(
            preview?.product?.creditLineAgreement.defaultCreditLimitCents
          )}{" "}
          to lower your credit utilization
        </>,
        "More tools to build your credit",
      ]
    : [
        "Instant 1-click submission for FREE",
        `Select up to ${CreditDisputes.maxItems.premium} items/month and have your mailing fee waived`,
        "Keep track of the status within the Kikoff app",
        `A higher credit line ${format.money(
          preview?.product?.creditLineAgreement.defaultCreditLimitCents
        )} to lower your credit utilization`,
      ];
};

export const selectDisputesEligibility = createLoadableSelector(
  (context: CreditDisputes.Context) => (state: RootState) =>
    !!state.creditDisputes.eligibilityByContext?.[context],
  {
    loadAction: () => fetchDisputesAvailability(),
    selectLoaded: () => (state) => state.creditDisputes.eligibilityByContext,
  }
);

export const selectDisputableItemsAvailable = () => (state: RootState) =>
  state.creditDisputes.disputableItemsAvailable;

export const selectNewDisputeItemsAvailable = () => (state: RootState) =>
  state.creditDisputes.newDisputeItemsAvailable;

export const selectDisputesDrawerToken = () => (state: RootState) =>
  state.creditDisputes.drawerToken;

export const selectDisputesReasonExplanationByReasonByCategory = createLoadableSelector(
  () => (state: RootState) =>
    state.creditDisputes.reasonExplanationByReasonByCategory,
  {
    loadAction: () => fetchDisputesAvailability(),
  }
);

export const selectMostRecentTransunionDispute = () => (state: RootState) =>
  maxBy(
    selectSentSubmissionTokens()(state)
      .filter((submissionToken) =>
        selectDisputeSubmission.isTransunion(submissionToken)(state)
      )
      .map(
        (submissionToken) =>
          selectDisputeSubmission(submissionToken)(state).transunion
      ),
    ({ sentAt }) => sentAt
  );

export const fetchCreditDisputableItems = Object.assign(
  (context: CreditDisputes.Context) =>
    thunk((dispatch, getState) =>
      Promise.all([
        dispatch(fetchCreditDisputableItems.dataOnly(context)),
        dispatch(fetchCreditDisputes()),
        dispatch(selectIsPremiumOrUltimate.loadAction.ifMissing()),
      ] as const).then(
        ([{ disputableItems }, disputes, isPremiumOrUltimate]) => {
          const state = getState();

          if (
            context === "SINGLE_BUREAU_EFX" &&
            selectDisputesEligibility("TRIBUREAU")(state)
          ) {
            dispatch(setDisputesContext("TRIBUREAU"));
          }

          const pendingDisputes = disputes.filter(CreditDispute.isPending);
          const pendingDispute = pendingDisputes[0];
          const pendingIs3b = selectDisputeSubmission.is3b(
            pendingDispute?.submissionToken
          )(state);
          const pendingIsTransunion = selectDisputeSubmission.isTransunion(
            pendingDispute?.submissionToken
          )(state);

          dispatch(
            updateDisputableItemReasons(
              Object.assign(
                Object.fromEntries(
                  disputableItems.map(({ tradelineId, possibleReasons }) => [
                    tradelineId,
                    context === "TRANSUNION_INSTANT"
                      ? []
                      : [possibleReasons[0]],
                  ])
                ),
                Object.fromEntries(
                  pendingDispute?.disputedItems.map(
                    ({ tradelineId, selectedReasons }) => [
                      tradelineId,
                      selectedReasons,
                    ]
                  ) || []
                )
              )
            )
          );

          if (pendingDispute) {
            if (
              selectDisputesContext()(state) === CreditDisputes.defaultContext
            ) {
              dispatch(
                setDisputesContext(
                  (() => {
                    if (
                      isPremiumOrUltimate &&
                      selectShow3bDisputes()(state) &&
                      pendingIs3b
                    ) {
                      return "TRIBUREAU";
                    }

                    if (pendingIsTransunion) {
                      return "TRANSUNION_INSTANT";
                    }

                    return "SINGLE_BUREAU_EFX";
                  })()
                )
              );
            }

            const pendingDisputeContext = (() => {
              if (pendingIs3b) {
                return "TRIBUREAU";
              }

              if (pendingIsTransunion) {
                return "TRANSUNION_INSTANT";
              }

              return "SINGLE_BUREAU_EFX";
            })();

            if (pendingDisputeContext === context) {
              dispatch(
                setCheckedDisputableItems({
                  context: pendingDisputeContext,
                  checkedItems: Object.fromEntries(
                    pendingDispute.disputedItems
                      // ready to mail letters are pending, but its items aren't
                      // disputable so we need to filter them out
                      .filter(
                        ({ tradelineId }) =>
                          tradelineId &&
                          disputableItems.some(
                            (item) => item.tradelineId === tradelineId
                          )
                      )
                      .map(({ tradelineId }) => [tradelineId, true])
                  ),
                })
              );
              // TODO: Remember to refactor selected_reason
              dispatch(
                updateDisputableItemCustomReason(
                  Object.fromEntries(
                    pendingDispute.disputedItems.map((item) => [
                      item.tradelineId,
                      item.customReason,
                    ])
                  )
                )
              );
            }
          }
        }
      )
    ),
  {
    dataOnly: (context: CreditDisputes.Context) =>
      thunk((dispatch) => dispatch(fetchDisputesContextDetails(context))),

    ifNotPresent: (context: CreditDisputes.Context) =>
      thunk((dispatch, getState) =>
        Promise.resolve(
          (getState().creditDisputes.detailsByContext[context]
            .disputableTradelineIds as never) ||
            dispatch(fetchCreditDisputableItems(context))
        ).then(() => {
          // TODO: Add support for return value if needed
        })
      ),
  }
);

export const saveDisputeSurveyResponse = ({
  letterToken,
  itemToken,
  responseRecieved,
  option,
  customResponse,
}:
  | {
      letterToken: string;
      itemToken?: null;
      responseRecieved: true;
      option: keyof typeof SurveyOption;
      customResponse: string;
    }
  | {
      letterToken: string;
      itemToken?: null;
      responseRecieved: false;
      option?: "NONE";
      customResponse?: null;
    }
  | {
      letterToken: string;
      itemToken: string;
      responseRecieved: true;
      option?: "ITEM_NOT_REMOVED";
      customResponse?: null;
    }) => {
  return webRPC.CreditDisputes.saveDisputeSurveyResponse({
    letterToken,
    itemToken,
    bureauResponseReceived: responseRecieved,
    responseOption: SurveyOption[option],
    customResponse,
  }).then(
    handleProtoStatus({
      SUCCESS() {},
      _DEFAULT: handleFailedStatus("Failed to save survey response."),
    })
  );
};

export const startCreditDispute = () =>
  thunk((dispatch, getState) => {
    const state = getState().creditDisputes;
    const context = state.context;
    const checkedByTradelineId =
      state.detailsByContext[context].checkedByTradelineId;

    const selectedReasons = Object.entries(state.reasonsByTradelineId)
      .filter(([id]) => checkedByTradelineId[id])
      .flatMap(([tradelineId, reasons]) => {
        return Object.values(
          state.itemIdByBureauByTradelineId[tradelineId]
        ).map((itemId) => {
          return {
            itemId,
            reasons: reasons.map((reason) => ({
              reason,
              detail:
                reason === web.public_.CreditDisputeItem.Reason.CUSTOM
                  ? state.customReasonsByTradelineId[tradelineId]
                  : "",
            })),
          };
        });
      });

    track("Disputes - Start Dispute", {
      selectedReasons: selectedReasons.map(({ itemId }) => itemId),
    });

    return webRPC.CreditDisputes.startDispute({
      context: web.public_.DisputeContext[context],
      selectedReasons,
    }).then(
      handleProtoStatus({
        SUCCESS({ disputes }) {
          dispatch(
            actions.setDisputes([
              ...Object.values(state.disputeByLetterToken).filter(
                invertResult(CreditDispute.isPending)
              ),
              ...disputes,
            ])
          );
          dispatch(
            actions.updateContextBySubmissionToken(
              Object.fromEntries(
                disputes.map((dispute) => [dispute.submissionToken, context])
              )
            )
          );

          dispatch(fetchUser.todos());
        },
        async REFRESH(data) {
          // We see users repeatedly try again without refreshing, so we're
          // going to refresh for them
          await dispatch(fetchCreditDisputableItems(context));
          await dispatch(
            setCheckedDisputableItems({ context, checkedItems: {} })
          );
          return handleFailedStatus("Refreshed data, please try again.")(data);
        },
        _DEFAULT: handleFailedStatus("Failed to generate letters."),
      })
    );
  });

const SUBMIT_ERROR_STATUSES: (keyof typeof web.public_.SubmitDisputeResponse.Status)[] = [
  "TRY_LATER_INTERNAL",
  "TRY_LATER_EXTERNAL",
  "FILE_MAINTENANCE",
  "INELIGIBLE_FOR_DISPUTES",
  "OPEN_DISPUTE",
];

export const submitCreditDispute = (() => {
  const submit = (payload: web.public_.ISubmitDisputeRequest) =>
    thunk((dispatch, getState) => {
      const state = getState();
      const submissionToken = selectDisputeSubmission.pending.token()(state);
      const is3b = selectDisputeSubmission.is3b(submissionToken)(state);
      const isTransunion = selectDisputeSubmission.isTransunion(
        submissionToken
      )(state);
      const context: CreditDisputes.Context = (() => {
        if (is3b) {
          return "TRIBUREAU";
        }

        if (isTransunion) {
          return "TRANSUNION_INSTANT";
        }

        return "SINGLE_BUREAU_EFX";
      })();

      return webRPC.CreditDisputes.submitDispute({
        context: web.public_.DisputeContext[context],
        submissionToken,
        ...payload,
      }).then(
        handleProtoStatus({
          SUCCESS() {
            dispatch(fetchCreditDisputes());
            if (["SINGLE_BUREAU_EFX", "TRIBUREAU"].includes(context)) {
              dispatch(fetchCreditDisputableItems("SINGLE_BUREAU_EFX"));
              dispatch(fetchCreditDisputableItems("TRIBUREAU"));
            } else {
              dispatch(fetchCreditDisputableItems(context));
            }
            if (payload.upgradeTo) {
              dispatch(updateOrders());
              dispatch(fetchPreviewChangeSubscriptionPlan(payload.upgradeTo));
              dispatch(fetchUser.creditLine());
            }
            dispatch(fetchRecommendations());

            if (!nativeDispatch("invalidate")) dispatch(fetchUser.todos());
          },
          _DEFAULT: handleFailedStatus("Failed to submit dispute."),

          ...Object.fromEntries(
            SUBMIT_ERROR_STATUSES.map((status) => [
              status,
              () => {
                dispatch(fetchCreditDisputableItems(context));
                Router.replace(
                  `/dashboard/credit-score/dispute/transunion/error/${web.public_.SubmitDisputeResponse.Status[status]}`
                );
                throw Error;
              },
            ])
          ),
        })
      );
    });
  return {
    mail: () => submit({ mailedByUser: true }),
    eFax: (paymentMethodToken?: string) => submit({ paymentMethodToken }),
    premiumUpgrade: () =>
      submit({ upgradeTo: web.public_.SubscriptionPlan.PREMIUM }),
    ultimateUpgrade: () =>
      submit({ upgradeTo: web.public_.SubscriptionPlan.ULTIMATE }),
    transunion: () => submit({}),
  };
})();

export const fetchCreditDisputes = Object.assign(
  () =>
    thunk((dispatch) =>
      Promise.all([
        dispatch(
          fetchCreditDisputes.dataOnly({
            context: "SINGLE_BUREAU_EFX",
          })
        ),
        dispatch(fetchCreditDisputes.dataOnly()),
        dispatch(
          fetchCreditDisputes.dataOnly({ context: "TRANSUNION_INSTANT" })
        ),
      ]).then(([singleBureauData, tribureauData, transunionData]) => {
        const disputes = [
          ...singleBureauData.disputes,
          ...tribureauData.disputes,
          ...transunionData.disputes,
        ];

        dispatch(actions.setDisputes(disputes));

        return disputes;
      })
    ),
  {
    ifNotPresent: () =>
      thunk((dispatch, getState) => {
        const {
          disputeByLetterToken,
          disputeLettersLoaded,
        } = getState().creditDisputes;
        return Promise.resolve(
          disputeLettersLoaded
            ? Object.values(disputeByLetterToken)
            : dispatch(fetchCreditDisputes())
        );
      }),
    dataOnly: ({
      context = "TRIBUREAU",
    }: {
      context?: CreditDisputes.Context;
    } = {}) =>
      thunk((dispatch) =>
        webRPC.CreditDisputes.listDisputes({
          context: web.public_.DisputeContext[context],
        }).then<web.public_.ListDisputesResponse>(
          handleProtoStatus({
            SUCCESS(data) {
              dispatch(
                actions.updateContextBySubmissionToken(
                  Object.fromEntries(
                    data.disputes.map((dispute) => [
                      dispute.submissionToken,
                      context,
                    ])
                  )
                )
              );

              return data;
            },
            _DEFAULT: handleFailedStatus("Failed to fetch credit disputes."),
          })
        )
      ),
  }
);

export const fetchDisputeLetterPdfLink = (letterToken: string) =>
  thunk((dispatch) =>
    webRPC.CreditDisputes.downloadDisputePdf({ letterToken }).then(
      handleProtoStatus({
        SUCCESS({ pdfLink }) {
          dispatch(actions.updatePdfLinks({ [letterToken]: pdfLink }));
        },
        _DEFAULT: handleFailedStatus("Failed to download dispute letter pdf."),
      })
    )
  );

export const markDisputeAsMailed = (submissionToken: string) =>
  thunk((dispatch, getState) =>
    Promise.all(
      Object.values(selectDisputeSubmission(submissionToken)(getState())).map(
        ({ letterToken }) =>
          webRPC.CreditDisputes.markAsMailed({ letterToken }).then(
            handleProtoStatus({
              SUCCESS() {
                // Continue
              },
              _DEFAULT: handleFailedStatus("Failed to mark as mailed."),
            })
          )
      )
    ).then(() => dispatch(fetchCreditDisputes()))
  );

export const fetchDisputesAvailability = Object.assign(
  () =>
    thunk((dispatch) =>
      webRPC.CreditDisputes.getDisputeAvailability({}).then(
        handleProtoStatus({
          SUCCESS(data) {
            batch(() => {
              dispatch(
                actions.setEligibilityByContext(
                  CreditDisputes.Eligibility.ByContext.fromList(
                    data.eligibilities
                  )
                )
              );
              dispatch(
                actions.setDisputableItemsAvailable(
                  data.disputableItemsAvailable
                )
              );
              dispatch(
                actions.setNewDisputeItemsAvailable(
                  data.newDisputeItemsAvailable
                )
              );
              dispatch(actions.setDrawerToken(data.drawerToken));
              dispatch(actions.setShow3b(data.showTribureauFlow));
              dispatch(
                actions.setReasonExplanationByReasonByCategory(
                  CreditDisputes.ReasonExplanation.ByReason.ByCategory.fromList(
                    data.reasonExplanations
                  )
                )
              );
            });

            return data;
          },
          _DEFAULT: handleFailedStatus("Failed to load disputes availability."),
        })
      )
    ),
  {
    ifNotPresent: () =>
      thunk((dispatch, getState) => {
        const { eligibilityByContext } = getState().creditDisputes;

        return Promise.resolve(
          eligibilityByContext || dispatch(fetchDisputesAvailability())
        ).then(() => {
          // TODO: Add support for return value if needed
        });
      }),
  }
);

export const fetchDisputesContextDetails = Object.assign(
  (context: CreditDisputes.Context) =>
    thunk(async (dispatch, getState) => {
      await dispatch(fetchDisputesAvailability.ifNotPresent());
      const eligible = selectDisputesEligibility(context)(getState());

      if (!eligible) {
        const disputableItems = [];
        dispatch(actions.setDisputableItems({ context, disputableItems }));
        return {
          disputableItems,
        } as web.public_.GetDisputeContextDetailsResponse;
      }

      return webRPC.CreditDisputes.getDisputeContextDetails({
        context: web.public_.DisputeContext[context],
      }).then(
        handleProtoStatus({
          SUCCESS(data) {
            dispatch(
              actions.setDisputableItems({
                context,
                disputableItems: data.disputableItems,
                nextDisputableItems: data.nextDisputableItems,
              })
            );
            dispatch(
              actions.updateDetailsByContext({
                [context]: {
                  maxItems: data.maxItemCount,
                  priceCents: data.priceCents,
                  nextAllowedAt: data.nextDisputableDate,
                } as ContextDetails,
              })
            );

            return data;
          },
          _DEFAULT: handleFailedStatus(
            "Failed to load disputes context details."
          ),
        })
      );
    }),
  {
    ifNotPresent: (context: CreditDisputes.Context) =>
      thunk((dispatch, getState) => {
        const { detailsByContext } = getState().creditDisputes;

        return Promise.resolve(
          detailsByContext[context].disputableTradelineIds ||
            dispatch(fetchDisputesContextDetails(context))
        ).then(() => {
          // TODO: add support for return value as needed
        });
      }),
  }
);

export const refreshDisputesContext = (context: CreditDisputes.Context) =>
  thunk((dispatch) => {
    return webRPC.CreditDisputes.refreshDisputeContext({
      context: web.public_.DisputeContext[context],
    }).then(
      handleProtoStatus({
        SUCCESS() {
          return dispatch(fetchCreditDisputableItems(context));
        },
        _DEFAULT: handleFailedStatus("Failed to refresh disputes context."),
      })
    );
  });

export const setDisputesContext = (context: CreditDisputes.Context) =>
  thunk((dispatch) => {
    dispatch(actions.setDisputesContext(context));

    return dispatch(fetchCreditDisputableItems.ifNotPresent(context));
  });

export namespace CreditDisputes {
  export const eFaxPriceCents = {
    basic: 1_00,
    premium: 0,
    ultimate: 0,
  };
  export const maxItems = {
    basic: 3,
    premium: 5,
  };
  export const estimateOffsetDays = 50;
  export const estimateOffsetDaysRange = (() => {
    const low = 45;
    const high = 60;
    return { low, high, text: `${low} to ${high} days` };
  })();

  export type UpgradeSource = "bureau_tabs" | "overlay" | "banner";

  export const defaultContext = defaultCreditDisputesContext;
  export const contexts = creditDisputesContexts;
  export type Context = keyof typeof web.public_.DisputeContext;

  export type Type = "ONLINE" | "MAILED";
  export const typeByContext: Record<
    CreditDisputes.Context,
    CreditDisputes.Type
  > = {
    SINGLE_BUREAU_EFX: "MAILED",
    TRIBUREAU: "MAILED",
    TRANSUNION_INSTANT: "ONLINE",
  };

  export type Eligibility = boolean & {};
  export namespace Eligibility {
    export type ByContext = Record<
      CreditDisputes.Context,
      CreditDisputes.Eligibility
    >;
    export namespace ByContext {
      export const fromList = (
        eligibilities: web.public_.GetDisputeAvailabilityResponse.IEligibility[]
      ) =>
        Table.createIndex(
          eligibilities,
          [({ context }) => web.public_.DisputeContext[context]],
          "eligible"
        );
    }
  }

  export type ReasonExplanation = web.public_.IReasonExplanation;
  export namespace ReasonExplanation {
    export type ByReason = Record<
      web.public_.CreditDisputeItem.Reason,
      ReasonExplanation
    >;
    export namespace ByReason {
      export type ByCategory = Record<
        web.public_.CreditDisputeItem.Category,
        ByReason
      >;
      export namespace ByCategory {
        export const fromList = (reasonExplanations: ReasonExplanation[]) =>
          Table.createIndex(reasonExplanations, ["category", "reason"]);
      }
    }
  }

  export namespace Transunion {
    export const phone = "8009168800";

    export const estimateOffsetDays = 30;
    export const estimateOffsetDaysRange = (() => {
      const low = 30;
      const high = 45;
      return { low, high, text: `${low}-${high} days` };
    })();

    export namespace PersonalInfoTitle {
      export const aka = "Also Known As";
      export const previousAddress = "Previous Address";
    }
  }
}

export type CreditDisputeItem = web.public_.ICreditDisputeItem;
export namespace CreditDisputeItem {
  const { Status } = web.public_.CreditDisputeItem;

  export type ReasonCategory = keyof typeof web.public_.CreditDisputeItem.ReasonCategory;

  export type ItemId = string & {};
  export type TradelineId = string & {};
  export type AccountId = string & {};
  export const isDisputed = (item?: CreditDisputeItem) =>
    item?.selectedReason !== web.public_.CreditDisputeItem.Reason.UNKNOWN;
  export const isRemaining = ({ status }: CreditDisputeItem) =>
    status === Status.READDED || status === Status.VERIFIED;
  export const isRemoved = ({ status }: CreditDisputeItem) =>
    status === Status.DELETED;
  export const wasDisputed = (item?: CreditDisputeItem) =>
    item?.status !== Status.READY_TO_DISPUTE;
  export const isRedisputed = (item: CreditDisputeItem) =>
    item.previousDisputeInfos.length > 1;
  export const hasPreviousLetter = (
    item: CreditDisputeItem,
    letterToken: CreditDispute.LetterToken
  ) =>
    item.previousDisputeInfos
      .slice(0, -1)
      .some((previousDispute) => previousDispute.letterToken === letterToken);
  export const withPreviousInfo = (
    item: CreditDisputeItem,
    letterToken: CreditDispute.LetterToken
  ) => ({
    ...item,
    ...item.previousDisputeInfos
      .slice(0, -1)
      .find((previousDispute) => previousDispute.letterToken === letterToken),
    status: Status.VERIFIED,
  });

  export namespace Transunion {
    export const isInReview = (item: CreditDisputeItem) =>
      item.status === web.public_.CreditDisputeItem.Status.DISPUTED;

    const redisputableStatuses: web.public_.CreditDisputeItem.Status[] = [
      web.public_.CreditDisputeItem.Status.VERIFIED_AS_ACCURATE,
      web.public_.CreditDisputeItem.Status.VERIFIED_AS_ACCURATE_AND_UPDATED,
      web.public_.CreditDisputeItem.Status.DISPUTED_INFORMATION_UPDATED,
      web.public_.CreditDisputeItem.Status
        .DISPUTED_INFORMATION_UPDATED_AND_OTHER_INFORMATION_UPDATED,
      web.public_.CreditDisputeItem.Status.REINSERTED,
      web.public_.CreditDisputeItem.Status.VERIFIED_AND_UPDATED,
      web.public_.CreditDisputeItem.Status.VERIFIED_AND_NO_CHANGE_NEEDED,
      web.public_.CreditDisputeItem.Status
        .VERIFIED_AS_ACCURATE_AND_NO_CHANGE_NEEDED,
      web.public_.CreditDisputeItem.Status.NO_CHANGE_NEEDED,
    ];
    export const isRedisputable = (item: CreditDisputeItem) =>
      redisputableStatuses.includes(item.status);
    export const isPersonalInfo = (item: CreditDisputeItem) =>
      item.category === web.public_.CreditDisputeItem.Category.PERSONAL_INFO;
  }
}

export type ItemIdByBureau = Record<Bureau, CreditDisputeItem.ItemId>;
export namespace ItemIdByBureau {
  export const defaultItemId = (itemIdByBureau: ItemIdByBureau) =>
    UObject.firstValue(itemIdByBureau);

  export type ByTradelineId = Record<
    CreditDisputeItem.TradelineId,
    ItemIdByBureau
  >;

  export namespace ByTradelineId {
    export const fromItemList = (items: CreditDisputeItem[]) =>
      Table.createIndex(
        items,
        [
          "tradelineId",
          ({ bureau }) => Bureau.byProtoEnum[bureau] as Bureau,
        ] as const,
        "itemId"
      );
  }
}

export type CreditDispute = web.public_.IDispute;
export namespace CreditDispute {
  export type LetterToken = string & {};
  export type SubmissionToken = string & {};

  export type ByLetterToken = Record<LetterToken, CreditDispute>;

  export const isPending = ({ status }: CreditDispute) =>
    status === web.public_.Dispute.Status.PENDING ||
    status === web.public_.Dispute.Status.READY_TO_MAIL;
  export const isReadyToMail = ({ status }: CreditDispute) =>
    status === web.public_.Dispute.Status.READY_TO_MAIL;
  export const hasNewResponse = ({ viewed, reviewedAt }: CreditDispute) =>
    !viewed && reviewedAt;
  export const isReviewed = ({ status }: CreditDispute) =>
    status === web.public_.Dispute.Status.REVIEWED;

  export namespace ByLetterToken {
    export const bureauConjunctionList = (
      submission: ByLetterToken,
      filter = (dispute: CreditDispute) => true as unknown
    ) =>
      conjunctionList(
        Object.values(submission)
          .filter(filter)
          .map(({ bureau }) => capitalize(Bureau.byProtoEnum[bureau])),
        "and"
      );
  }

  export namespace LetterToken {
    export type ByBureau = Record<Bureau, LetterToken>;

    export namespace ByBureau {
      export const defaultLetterToken = (letterTokenByBureau: ByBureau) =>
        UObject.firstValue(letterTokenByBureau);

      export type BySubmissionToken = Record<SubmissionToken, ByBureau>;
      export namespace BySubmissionToken {
        export const fromDisputeList = (
          disputes: CreditDispute[]
        ): BySubmissionToken =>
          Table.createIndex(
            disputes,
            [
              "submissionToken",
              ({ bureau }) => Bureau.byProtoEnum[bureau] as Bureau,
            ] as const,
            "letterToken"
          );
      }
    }
  }
}

const getNthThursday = (year: number, month: number, n: number): Date => {
  const firstDayOfMonth = startOfMonth(new Date(year, month - 1));
  const dayOfWeek = getDay(firstDayOfMonth);

  const daysToAdd = (4 - dayOfWeek + 7) % 7;
  const firstThursday = addDays(firstDayOfMonth, daysToAdd);
  const nthThursday = addWeeks(firstThursday, n - 1);

  return nthThursday;
};

export const useDisputeTakeoverDismissalToken = (): string | null => {
  // Calculate this month window of dispute takeover
  const today = new Date();
  // For month, frontend is zero-based index while mobile is one-based index
  // Add one here to stay consistent
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();
  const firstThursday = getNthThursday(currentYear, currentMonth, 1);

  if (today >= firstThursday) {
    return `${currentYear}-${currentMonth}`;
  }
  return null;
};

export const useDisputeResponsePopup = (): Popup => {
  const dispatch = useDispatch();
  const submissionToken =
    useSelector(selectNewDisputeResponse.submissionToken()) ?? "";
  const newResponseSubmission = useSelector(selectNewDisputeResponse());

  const reviewedAt = Math.max(
    ...Bureau.list.map(
      (bureau) => protoTime(newResponseSubmission?.[bureau]?.reviewedAt) ?? 0
    )
  );

  const tagValue = `${submissionToken}_${reviewedAt}`;
  const firstResponse = !useSelector(
    selectDismissal("DISPUTE_UPDATED_DRAWER", submissionToken)
  );
  const showDrawer = submissionToken?.length > 0 && reviewedAt !== 0;

  return {
    dismissalTag: "DISPUTE_UPDATED_DRAWER",
    dismissalToken: tagValue,
    name: "src/pages/dashboard/credit-score/_views/dispute_response",
    popupType: PopupType.OVERLAY,
    params: {
      submissionToken,
      firstResponse,
    },
    onPop: () => {
      if (firstResponse) {
        dispatch(dismiss("DISPUTE_UPDATED_DRAWER", submissionToken));
      }
    },
    isEligible: showDrawer,
  };
};

export const useShowDisputesUpsell = () => {
  const [preview] = useSelector(
    selectChangeSubscriptionPlanPreview.load("premium")
  );

  return preview?.allowUpgrade;
};

export type TribureauDisputesV2Variant =
  | "control"
  | "overlay_and_banner"
  | "banner"
  | "overlay";
export const useTribureauDisputesV2Variant = () => {
  return useBackendExperiment(
    "tribureauDisputesV2"
  ) as TribureauDisputesV2Variant;
};
