import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StaticImageData } from "next/image";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";
import { format } from "@kikoff/utils/src/string";

import cellImg from "@page/dashboard/bill-reporting/images/cell.svg";
import electricityImg from "@page/dashboard/bill-reporting/images/electricity.svg";
import naturalGasImg from "@page/dashboard/bill-reporting/images/natural-gas.svg";
import waterImg from "@page/dashboard/bill-reporting/images/water.svg";
import { RootState } from "@store";
import { track } from "@util/analytics";

import { createLoadableSelector, thunk } from "../utils";

import { fetchPaymentMethods } from "./funds";
import { selectFeatureFlag } from "./page";
import { selectActiveSubscription } from "./shopping";

// All objects map from a utilityType to the data for that utilityType
const initialState = {
  onboarding: false as boolean,
  utilityProviders: {} as Partial<
    Record<web.public_.Bill.UtilityType, web.public_.Bill.IUtilityProvider[]>
  >,
  utilityTerms: null as Partial<
    Record<web.public_.Bill.UtilityType, web.public_.Bill.IUtilityTerm>
  >,
  potentialBillTransactionsResponse: {} as Partial<
    Record<
      web.public_.Bill.UtilityType,
      web.public_.GetPotentialBillTransactionsResponse
    >
  >,
};

export type BillReportingState = typeof initialState;

// ### Reducers
const billReportingSlice = createSlice({
  name: "billReporting",
  initialState,
  reducers: {
    setOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.onboarding = payload;
    },
    addUtilityProviders(
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          Record<
            web.public_.Bill.UtilityType,
            web.public_.Bill.IUtilityProvider[]
          >
        >
      >
    ) {
      state.utilityProviders = {
        ...state.utilityProviders,
        ...payload,
      };
    },
    addUtilityTerm(
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          Record<web.public_.Bill.UtilityType, web.public_.Bill.IUtilityTerm>
        >
      >
    ) {
      state.utilityTerms = {
        ...state.utilityTerms,
        ...payload,
      };
    },
    removeUtilityTerm(
      state,
      { payload }: PayloadAction<web.public_.Bill.UtilityType>
    ) {
      delete state.utilityTerms[payload];
    },
    addPotentialTransactions(
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          Record<
            web.public_.Bill.UtilityType,
            web.public_.GetPotentialBillTransactionsResponse
          >
        >
      >
    ) {
      state.potentialBillTransactionsResponse = {
        ...state.potentialBillTransactionsResponse,
        ...payload,
      };
    },
  },
});

const { actions } = billReportingSlice;
export default billReportingSlice.reducer;

// ### Selectors

export const selectOnboardingState = (state: RootState) =>
  state.billReporting.onboarding;

export const selectUtilityProviders = createLoadableSelector(
  (utilityType: web.public_.Bill.UtilityType) => (state: RootState) =>
    state.billReporting.utilityProviders[utilityType],
  {
    loadAction: (utilityType: web.public_.Bill.UtilityType) =>
      getUtilityProviders(utilityType),
  }
);

export const selectUtilityTerms = createLoadableSelector(
  () => (state: RootState) => state.billReporting.utilityTerms,
  {
    loadAction: () => getUtilityTerms(),
  }
);

export const selectPotentialBillTransactions = createLoadableSelector(
  (utilityType: web.public_.Bill.UtilityType, utilityTermToken: string) => (
    state: RootState
  ) => state.billReporting.potentialBillTransactionsResponse[utilityType],
  {
    loadAction: (utilityType, utilityTermToken) =>
      getPotentialBillTransactions(utilityType, utilityTermToken),
  }
);

export function setOnboarding(onboarding: boolean) {
  return actions.setOnboarding(onboarding);
}

// ### API calls
export function getUtilityProviders(utilityType: web.public_.Bill.UtilityType) {
  return thunk((dispatch) =>
    webRPC.BillReporting.getUtilityProviders({
      utilityType,
    }).then<web.public_.GetUtilityProvidersResponse>(
      handleProtoStatus({
        SUCCESS(data) {
          const sortedUtilityProviders =
            data?.utilityProviders
              ?.sort((a, b) => (a?.name < b?.name ? -1 : 1))
              .filter((x) => x) || [];
          dispatch(
            actions.addUtilityProviders({
              [utilityType]: sortedUtilityProviders,
            })
          );
        },
        _DEFAULT: handleFailedStatus("Failed to get utility providers."),
      })
    )
  );
}

export function getUtilityTerms() {
  return thunk((dispatch) =>
    webRPC.BillReporting.getUtilityTerms(
      {}
    ).then<web.public_.GetUtilityTermsResponse>(
      handleProtoStatus({
        SUCCESS(data) {
          // Format data to match Record<web.public_.Bill.UtilityType, web.public_.Bill.IUtilityTerm>>
          const utilityTerms = {};
          for (const term of data.utilityTerms) {
            // check if term is valid
            if (term?.endedAt || !term?.utilityProvider?.utilityType) continue;
            utilityTerms[term.utilityProvider.utilityType] = term;
          }
          dispatch(actions.addUtilityTerm(utilityTerms));
        },
        _DEFAULT: handleFailedStatus("Failed to get Bill Reporting info."),
      })
    )
  );
}

export function createUtilityTerm(
  utilityType: web.public_.Bill.UtilityType,
  utilityProviderName: string
) {
  return thunk((dispatch) =>
    webRPC.BillReporting.createUtilityTerm({
      utilityType,
      utilityProviderName,
    }).then<web.public_.CreateUtilityTermResponse>(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.addUtilityTerm({ [utilityType]: data.utilityTerm }));
          return data;
        },
        ALREADY_COMPLETED(data) {
          dispatch(actions.addUtilityTerm({ [utilityType]: data.utilityTerm }));
          return data;
        },
        _DEFAULT: handleFailedStatus("Failed to register provider."),
      })
    )
  );
}

export function updateUtilityTerm(
  utilityTerm: web.public_.Bill.IUtilityTerm,
  tradeline: "open" | "close" | null,
  externalTransactionToken?: string
) {
  const utilityType = utilityTerm.utilityProvider.utilityType;
  const token = utilityTerm.token;
  const utilityProviderName = utilityTerm.utilityProvider.name;

  return thunk((dispatch) =>
    webRPC.BillReporting.updateUtilityTerm({
      token,
      utilityProviderName,
      externalTransactionToken,
      openTradeline: tradeline === "open",
      closeTradeline: tradeline === "close",
    }).then<web.public_.UpdateUtilityTermResponse>(
      handleProtoStatus({
        SUCCESS(data) {
          if (tradeline === "close") {
            dispatch(actions.removeUtilityTerm(utilityType));
          } else {
            dispatch(
              actions.addUtilityTerm({ [utilityType]: data.utilityTerm })
            );
          }
          return data;
        },
        ALREADY_COMPLETED() {
          if (tradeline === "close") {
            dispatch(actions.removeUtilityTerm(utilityType));
          }
        },
        _DEFAULT() {
          handleFailedStatus("Failed to register transaction.");
        },
      })
    )
  );
}

export function getPotentialBillTransactions(
  utilityType: web.public_.Bill.UtilityType,
  utilityTermToken: string
) {
  return thunk((dispatch) =>
    webRPC.BillReporting.getPotentialBillTransactions({
      utilityTermToken,
    }).then<web.public_.GetPotentialBillTransactionsResponse>(
      handleProtoStatus({
        SUCCESS(data) {
          if (data?.potentialBillTransactions?.length === 0) {
            track("Bill Reporting - Onboarding - No Transactions Found");
          } else if (data?.potentialBillTransactions?.length > 0) {
            track("Bill Reporting - Onboarding - Some Transactions Found");
          }
          dispatch(
            actions.addPotentialTransactions({
              [utilityType]: data,
            })
          );

          return data;
        },
        _DEFAULT(data) {
          // tracked this way to match mobile
          if (
            data.status ===
              web.public_.GetPotentialBillTransactionsResponse.Status.UNKNOWN ||
            data.status ===
              web.public_.GetPotentialBillTransactionsResponse.Status.FAILED
          ) {
            track.impression(
              "Bill Reporting - Onboarding - No Transactions View"
            );
          }
          dispatch(
            // we store the entire response so dispatch even on failure
            actions.addPotentialTransactions({
              [utilityType]: data,
            })
          );
          return handleFailedStatus("Failed to load bank transactions.");
        },
      })
    )
  );
}

// enroll bill reporting
export function enrollBillReporting() {
  return thunk(() =>
    webRPC.BillReporting.enrollBillReporting(
      {}
    ).then<web.public_.EnrollBillReportingResponse>(
      handleProtoStatus({
        SUCCESS(data) {},
        _DEFAULT: handleFailedStatus("Failed to enroll in Bill Reporting."),
      })
    )
  );
}

// Utils

export const billReportingTermsAndConditionsLink =
  "https://assets.kikoff.com/user-agreements/bill-reporting/Bill+Reporting+Terms+and+Conditions.pdf";
export const privacyPolicyLink = "https://kikoff.com/privacy-policy.pdf";

export interface Utility {
  name: string;
  iconSrc: StaticImageData;
  id: string;
  utilityType: web.public_.Bill.UtilityType;
}

export const utilities: Utility[] = [
  {
    id: "phone",
    name: "Phone",
    iconSrc: cellImg,
    utilityType: web.public_.Bill.UtilityType.PHONE,
  },
  {
    id: "electricity",
    name: "Electricity",
    iconSrc: electricityImg,
    utilityType: web.public_.Bill.UtilityType.ELECTRICITY,
  },
  {
    name: "Natural Gas",
    iconSrc: naturalGasImg,
    id: "natural-gas",
    utilityType: web.public_.Bill.UtilityType.GAS,
  },
  {
    name: "Water",
    iconSrc: waterImg,
    id: "water",
    utilityType: web.public_.Bill.UtilityType.WATER,
  },
];

export function getUtilityTermName(
  utilityTerm: web.public_.Bill.IUtilityTerm
): string {
  return (
    utilities.find(
      (utility) =>
        utility.utilityType === utilityTerm?.utilityProvider.utilityType
    )?.name || "Unknown"
  );
}

export function fetchUserHasLinkedBank() {
  return thunk((dispatch) =>
    dispatch(fetchPaymentMethods()).then((paymentMethods) => {
      return !!paymentMethods.find(
        (paymentMethod) =>
          paymentMethod?.status === "active" &&
          paymentMethod?.paymentType === "bank" &&
          !paymentMethod?.externalBankAccount?.actions?.find(
            (action) => action.authentication
          )
      );
    })
  );
}

const furnishOnDayOfMonthEstimate = 3; // we estimate that furnishing happens on the 3rd of every month
export function getNextEstimatedFurnishingDate(): string {
  const nextMonth = new Date();
  nextMonth.setDate(furnishOnDayOfMonthEstimate);
  nextMonth.setMonth(nextMonth.getMonth() + 1);

  return format.date(nextMonth, "Mmm dd, yyyy");
}

export function formatDateWith3LetterMonth(
  dateAsNumberStringOrDate: number | string | Date
): string {
  const date = new Date(dateAsNumberStringOrDate);
  return format.date.withConfig({ zone: "UTC" })(date, "Mmm yyyy");
}

export function useBillReportingEnabled() {
  const [userSubscription, loading] = useSelector(
    selectActiveSubscription.load()
  );
  const billReportingEnabled = useSelector(selectFeatureFlag("bill_reporting"));

  return [billReportingEnabled && !!userSubscription, loading];
}

export function getUtilityTerm(
  utilityType: web.public_.Bill.UtilityType,
  utilityTerms: Record<string, web.public_.Bill.IUtilityTerm>
): web.public_.Bill.IUtilityTerm {
  return utilityTerms?.[utilityType] ?? null;
}

export function uploadBankStatement(utilityTermToken: string, files: File[]) {
  return thunk(async () =>
    webRPC.BillReporting.uploadBankStatement({
      utilityTermToken,
      bankStatementFiles: await Promise.all(
        files.map(async (file) => ({
          mimeType: file.type,
          fileContent: new Uint8Array(await file.arrayBuffer()),
        }))
      ),
    }).then(
      handleProtoStatus({
        SUCCESS() {
          return true;
        },
        _DEFAULT: handleFailedStatus("Failed to upload bank statement."),
      })
    )
  );
}

export function useCompletedTerms(): {
  completedTerms: web.public_.Bill.IUtilityTerm[];
  hasCompletedAtLeastOne: boolean;
  loading: boolean;
} {
  const dispatch = useDispatch();

  const [utilityTerms, loading] = useSelector(selectUtilityTerms.load());

  const completedTerms = useMemo(
    () =>
      Object.entries(utilityTerms || [])
        .filter(
          ([utilityType, utilityTerm]) =>
            utilityType !== undefined && utilityTerm?.lastTransaction
        )
        .map(([_, utilityTerm]) => utilityTerm),
    [utilityTerms]
  );

  const hasCompletedAtLeastOne = useMemo(() => completedTerms.length > 0, [
    completedTerms,
  ]);

  useEffect(() => {
    // keep track of whether to show onboarding view or not (based on if user has any utility terms on first load)
    dispatch(setOnboarding(!hasCompletedAtLeastOne));
  }, [hasCompletedAtLeastOne]);

  return {
    completedTerms,
    hasCompletedAtLeastOne,
    loading,
  };
}
