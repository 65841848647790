import { useSelector } from "react-redux";
import Image from "next/image";

import KButton from "@kikoff/components/src/v1/buttons/KButton";
import Alert from "@kikoff/components/src/v1/info/Alert";
import { ChipV2 } from "@kikoff/components/src/v1/info/Chip";
import KLink from "@kikoff/components/src/v1/navigation/KLink";
import { web } from "@kikoff/proto/src/protos";
import { format } from "@kikoff/utils/src/string";

import Line from "@component/line";
import { useCreditChangesEnabled } from "@feature/credit";
import { CreditAlert, CreditAlerts } from "@feature/credit_alerts";
import { selectNewDisputeAllowed } from "@feature/credit_disputes";
import { track } from "@util/analytics";

import globeImg from "../assets/globe.svg";
import phoneImg from "../assets/phone.svg";

import styles from "./content.module.scss";

interface NewContentProps {
  creditAlert: CreditAlert;
}

export default function NewContent({ creditAlert }: NewContentProps) {
  const contactInfo = CreditAlerts.Bureau.contactInfo(CreditAlerts.bureau);
  const creditChangesEnabled = useCreditChangesEnabled();

  const disputesAllowed = useSelector(selectNewDisputeAllowed.forAll());

  const alertType = web.public_.CreditMonitor.AlertType[creditAlert.alertType];
  const changeType =
    web.public_.CreditMonitor.AlertType[creditAlert.alertChangeType];

  return (
    <div className={styles["new-content"]}>
      <div className="text:regular++ mb-2">If “YES”</div>
      <div className="mb-2">
        Check your next score update to understand any impact.
      </div>
      {creditChangesEnabled && (
        <KLink
          variant="unstyled"
          href={`/dashboard/credit-score/${CreditAlerts.bureau}/credit-changes`}
          onClick={() => {
            track("Credit Score - Credit Alerts - View Credit Changes Button", {
              alertType,
              changeType,
            });
          }}
        >
          <KButton
            className="text:small+ mb-2"
            variant="black-outline"
            size="small"
          >
            View Credit Changes
          </KButton>
        </KLink>
      )}
      <Alert type="info" dugout>
        This update could affect your score in a future credit report.
      </Alert>
      <Line spacing={16} />
      <div className="text:regular++ mb-2">If “NO”</div>
      <div>Here are some actions you can take:</div>
      <ul className={`${styles["outer-list"]} mb-4`}>
        {disputesAllowed && (
          <li>
            <div className="text:regular+ mb-2">
              For potential errors, initiate a dispute directly through Kikoff.
            </div>
            <KLink
              variant="unstyled"
              href="/dashboard/disputes/new"
              mobileBehavior="pushWebview"
              onClick={() => {
                track.tap("Credit Score - Credit Alerts - Dispute Now Button", {
                  alertType,
                  changeType,
                });
              }}
            >
              <KButton
                className="text:small+"
                variant="black-outline"
                size="small"
              >
                Dispute now
              </KButton>
            </KLink>
          </li>
        )}
        <li>
          <div className="text:regular+ mb-2">
            If unexpected, you have several options:
          </div>
          <ul className={`${styles["inner-list"]} text:small`}>
            <li>Contact Equifax directly, see contact info below.</li>
            <li>Request a credit lock.</li>
            <li>Request a credit freeze.</li>
            <li>Place a fraud alert on your report.</li>
          </ul>
        </li>
      </ul>
      <div className="text:regular+ mb-2">Contact Equifax</div>
      {contactInfo.url && (
        <KLink
          variant="unstyled"
          newLine={false}
          newTab
          mobileBehavior="pushWebview"
          href={contactInfo.url}
          onClick={() => {
            track("Credit Score - Credit Alerts - Website Button", {
              alertType,
              changeType,
            });
          }}
        >
          <ChipV2
            className="mr-2"
            icon={<Image height={16} src={globeImg} />}
            color="var(--blue-color)"
            inline
          >
            Website
          </ChipV2>
        </KLink>
      )}
      {contactInfo.phoneNumber && (
        <KLink
          variant="unstyled"
          newLine={false}
          href={`tel:+${contactInfo.phoneNumber}`}
          onClick={() => {
            track.tap("Credit Score - Credit Alerts - Phone Number", {
              alertType,
              changeType,
            });
          }}
        >
          <ChipV2
            icon={<Image height={14} src={phoneImg} />}
            color="var(--blue-color)"
            inline
          >
            {format.phone(contactInfo.phoneNumber)}
          </ChipV2>
        </KLink>
      )}
    </div>
  );
}
