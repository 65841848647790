import { useDispatch, useSelector } from "react-redux";
import Image from "next/image";
import Router, { useRouter } from "next/router";

import { isIOS, isMobile, isTablet } from "@kikoff/client-utils/src/dom";
import KButton from "@kikoff/components/src/v1/buttons/KButton";
import useInitial from "@kikoff/hooks/src/useInitial";
import { msIn } from "@kikoff/utils/src/date";
import { cls } from "@kikoff/utils/src/string";

import iconImg from "@app/public/apple-icon-180x180.png";
import { selectFeatureFlag } from "@feature/page";
import { logout } from "@feature/user";
import { track } from "@util/analytics";
import { l10nResources } from "@util/l10n";
import { isWebview, KIKOFF_APP_LINKS } from "@util/mobile";
import { TrackImpression } from "@util/track-impression";

import appInHandImg from "./images/app_in_hand.png";
import starImg from "./images/star.svg";

import styles from "./download_app_prompt.module.scss";

export function useShowDownloadAppPrompt() {
  const showDownloadAppPrompt = useSelector(
    selectFeatureFlag("download_app_prompt")
  );
  const user = useSelector((state) => state.user.proto);
  const initial = useInitial({ rerender: true });

  if (initial) return false;

  if (
    new URLSearchParams(window?.location?.search)?.get(
      "show-download-app-prompt"
    )
  ) {
    return true;
  }

  // don't show if user was created in the last day
  if (user?.createdAt?.seconds) {
    const msSinceCreated = Date.now() - user.createdAt.seconds * 1000;
    if (msSinceCreated < msIn.day) {
      return false;
    }
  }

  return (
    showDownloadAppPrompt &&
    isMobile &&
    !isTablet &&
    !isWebview &&
    !Router.pathname.startsWith("/dashboard/store") &&
    ((user && Router.pathname.startsWith("/dashboard")) ||
      Router.pathname.startsWith("/login"))
  );
}
const l10n = l10nResources({
  en: `hello-title = Hello
download-app-title = Open the Kikoff app!
download-app-subtitle = Kikoff is best experienced on our mobile app
feature-subtitle = The {$feature} is only available on our mobile app.
logout-button = Logout
open-app-button = Open Kikoff app
open-app-subtitle = You need to have the Kikoff app installed for this link to work.
download-app-button = Get the app
maybe-later-button = Maybe later
`,
  es: `hello-title = Hola
download-app-title = Abre la app de Kikoff!
download-app-subtitle = Kikoff es mejor en nuestra app móvil
feature-subtitle = El {$feature} solo está disponible en nuestra aplicación móvil.
logout-button = Cerrar sesión
open-app-button = Abrir la aplicación
open-app-subtitle = Necesitas tener la app de Kikoff instalada para que este enlace funcione.
download-app-button = Obtener la aplicación
maybe-later-button = Quizás más tarde
`,
});
interface DownloadAppPromptProps {
  feature?: "Bills Tab";
  featureLink?: string;
}

export default function DownloadAppPrompt({
  feature,
  featureLink,
}: DownloadAppPromptProps) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.proto);
  const initial = useInitial({ rerender: true });
  const router = useRouter();

  const showLogout = !initial && !router.pathname.startsWith("/login");

  return (
    <TrackImpression
      trackFn={() =>
        track.impression("Download App Prompt", {
          feature,
        })
      }
    >
      <div className="column">
        {showLogout && (
          <KButton
            onClick={() => {
              track.tap("Download App Prompt - Logout Button", {
                feature,
              });
              dispatch(logout("/login"));
            }}
            className={styles["logout-button"]}
            variant="text-underline"
            size="small"
          >
            {l10n.getString("logout-button")}
          </KButton>
        )}

        <div className={styles.container}>
          <div className="text:heading-3 text-center">
            {`${l10n.getString("hello-title")} ${user?.info?.givenName ?? ""}`}
          </div>

          <div className={styles["image-wrapper"]}>
            <Image src={appInHandImg} alt="app in hand" />
          </div>

          <div className="text:heading-3 text-center">
            {l10n.getString("download-app-title")}
          </div>
          {feature ? (
            <div className="text:large mt-2 text-center">
              {l10n.getString("feature-subtitle", { feature })}
            </div>
          ) : (
            <div className="text:large mt-2 text-center">
              {l10n.getString("download-app-subtitle")}
            </div>
          )}

          <div className="column mt-3">
            <KButton
              onClick={() => {
                track.tap("Download App Prompt - Download Button", {
                  feature,
                  featureLink: featureLink ?? "kikoff://",
                });
                if (featureLink) {
                  router.replace(featureLink);
                } else {
                  window.location.href = "kikoff://";
                }
              }}
              className="text:regular+"
              variant="green-gradient"
              size="standard"
            >
              {l10n.getString("open-app-button")}
            </KButton>

            {featureLink && (
              <KButton
                onClick={() => {
                  track.tap("Download App Prompt - Maybe Later Button", {
                    feature,
                  });
                  router.push("/dashboard");
                }}
                className="text:regular+"
                variant="text-underline"
                size="standard"
              >
                {l10n.getString("maybe-later-button")}
              </KButton>
            )}
          </div>

          <div className="text:small text-center color:moderate mt-2">
            {l10n.getString("open-app-subtitle")}
          </div>

          <div
            className={cls(styles["bottom-banner"], "color:strong-on-inverse")}
          >
            <div className="row align-center gap-1">
              <img src={iconImg.src} alt="icon" width={45} height={45} />
              <div className="column">
                <div className="text:regular+">Kikoff</div>
                <div className="row align-center">
                  <img src={starImg.src} alt="star" />
                  <img src={starImg.src} alt="star" />
                  <img src={starImg.src} alt="star" />
                  <img src={starImg.src} alt="star" />
                  <img className="mr-0.5" src={starImg.src} alt="star" />
                  <div className="text:small">158k</div>
                </div>
              </div>
            </div>
            <KButton
              onClick={() => {
                track.tap("Download App Prompt - Download Button", {
                  feature,
                });
                if (isIOS) {
                  window.open(KIKOFF_APP_LINKS.ios, "_blank");
                } else {
                  window.open(KIKOFF_APP_LINKS.android, "_blank");
                }
              }}
              variant="black"
              size="small"
            >
              {l10n.getString("download-app-button")}
            </KButton>
          </div>
        </div>
      </div>
    </TrackImpression>
  );
}
