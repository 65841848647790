import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { memo } from "@kikoff/utils/src/function";
import { isClient } from "@kikoff/utils/src/general";
import { UObject } from "@kikoff/utils/src/object";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { RootState } from "@store";
import analytics from "@util/analytics";

import { createLoadableSelector, thunk } from "../utils";

import { initCreditV2 } from "./credit";

export type CoachingTask = web.public_.ICoachingTask & {
  account?: web.public_.IAccountV2;
  recommendation?: web.public_.IRecommendation;
  invalidRecommendation?: boolean;
};

export namespace CoachingTask {
  export type Token = string & {};
  export type Group = "addPositive" | "reduceNegative" | "fixErrors";
  export namespace Type {
    export const group = (() => {
      const map = {
        [web.public_.CoachingTask.Type.LOWER_UTILIZATION]: "reduceNegative",
        [web.public_.CoachingTask.Type.DISPUTE]: "fixErrors",
        [web.public_.CoachingTask.Type.PAY_FOR_DELETE]: "reduceNegative",
        [web.public_.CoachingTask.Type.REOPEN_ACCOUNT]: "addPositive",
        [web.public_.CoachingTask.Type.AUTHORIZED_USER]: "addPositive",
      } as const;

      return (type: web.public_.CoachingTask.Type) =>
        map[type] as UObject.Values<typeof map>;
    })();

    export const factorsImpacted = (() => {
      const map = {
        [web.public_.CoachingTask.Type.LOWER_UTILIZATION]: ["cardUsage"],
        [web.public_.CoachingTask.Type.DISPUTE]: ["paymentHistory"],
        [web.public_.CoachingTask.Type.PAY_FOR_DELETE]: [
          "paymentHistory",
          "cardUsage",
        ],
        [web.public_.CoachingTask.Type.REOPEN_ACCOUNT]: [
          "creditAge",
          "creditMix",
        ],
        [web.public_.CoachingTask.Type.AUTHORIZED_USER]: [
          "paymentHistory",
          "creditAge",
          "creditMix",
          "cardUsage",
        ],
      } as const;

      return (type: web.public_.CoachingTask.Type) =>
        map[type as keyof typeof map];
    })();
    export const needsAccount = (() => {
      const tasksThatNeedAccount = [
        web.public_.CoachingTask.Type.PAY_FOR_DELETE,
        web.public_.CoachingTask.Type.LOWER_UTILIZATION,
        web.public_.CoachingTask.Type.REOPEN_ACCOUNT,
      ];

      return (type: web.public_.CoachingTask.Type) =>
        tasksThatNeedAccount.includes(type);
    })();
  }
}

const initialState = {
  taskByToken: {} as Record<CoachingTask.Token, CoachingTask>,
  suggested: null as CoachingTask.Token[] | null,
  inProgress: null as CoachingTask.Token[] | null,
  completed: null as CoachingTask.Token[] | null,
  dismissed: [] as CoachingTask.Token[] | null,
};

export type CoachingState = typeof initialState;

const coachingSlice = createSlice({
  name: "coaching",
  initialState,
  reducers: {
    updateTasksByToken(
      state,
      { payload }: PayloadAction<CoachingState["taskByToken"]>
    ) {
      Object.assign(state.taskByToken, payload);
      const groups = Object.values(state.taskByToken)
        .filter(({ token }) => !state.dismissed.includes(token))
        .groupBy((task) => {
          // eslint-disable-next-line no-param-reassign
          task.status ??= web.public_.CoachingTask.Status.SUGGESTED;
          if (task.status === web.public_.CoachingTask.Status.SUGGESTED)
            return "suggested";
          if (task.status === web.public_.CoachingTask.Status.CONFIRMED)
            return "completed";
          return "inProgress";
        });

      state.suggested = groups.suggested?.map(({ token }) => token) || [];
      state.inProgress = groups.inProgress?.map(({ token }) => token) || [];
      state.completed = groups.completed?.map(({ token }) => token) || [];
    },
    dismiss(state, { payload }: PayloadAction<CoachingTask.Token>) {
      state.dismissed.push(payload);
      coachingSlice.caseReducers.updateTasksByToken(state, {
        type: "coaching/updateTasksByToken",
        payload: {},
      });
    },
  },
});

const { actions } = coachingSlice;
export const {} = actions;
export default coachingSlice.reducer;

const trackInvalidTaskState = memo(
  (
    token: CoachingTask.Token,
    error: "accountNotFound" | "detailsMissing" | "missingRecommendationInfo"
  ) => (task: web.public_.ICoachingTask) => {
    if (isClient)
      analytics.track("Engagement Todos - Invalid Task State", {
        type: task.type,
        token,
        error,
        accountId: task.accountId,
      });
  }
);

export const selectTask = createLoadableSelector(
  (token: string) => (state: RootState): CoachingTask => {
    const task = state.coaching.taskByToken[token];
    if (!task) return null;
    if (
      CoachingTask.Type.needsAccount(task.type) &&
      !state.credit.reportAccountById[task.accountId]
    ) {
      trackInvalidTaskState(token, "accountNotFound")(task);
      return null;
    }
    if (
      task.type === web.public_.CoachingTask.Type.LOWER_UTILIZATION &&
      !task.lowerUtilization
    ) {
      trackInvalidTaskState(token, "detailsMissing")(task);
      return null;
    }
    if (task.invalidRecommendation) {
      trackInvalidTaskState(token, "missingRecommendationInfo")(task);
      return null;
    }

    return { ...task, account: state.credit.reportAccountById[task.accountId] };
  },
  {
    loadAction: () => fetchTasks(),
  }
);

export const selectSuggestedTasks = createLoadableSelector(
  () => (state: RootState) =>
    state.coaching.suggested
      ?.map((token) => selectTask(token)(state))
      .filter(Boolean),
  {
    loadAction: () => fetchTasks(),
  }
);

export const selectInProgressTasks = createLoadableSelector(
  () => (state: RootState) =>
    state.coaching.inProgress
      ?.map((token) => selectTask(token)(state))
      .filter(Boolean),
  {
    loadAction: () => fetchTasks(),
  }
);

export const selectAnnotatedTasks = Object.assign(
  createLoadableSelector(
    () => (state: RootState) =>
      state.coaching.suggested
        ?.concat(state.coaching.inProgress)
        .map((token) => selectTask(token)(state))
        .filter((task) => task?.annotationComment),
    {
      loadAction: () => fetchTasks(),
    }
  ),
  {
    lowerUtilization: () => (state: RootState) => {
      return state.coaching.suggested
        ?.concat(state.coaching.inProgress)
        .map((token) => selectTask(token)(state))
        .filter(
          (task) =>
            task?.annotationComment &&
            task.type === web.public_.CoachingTask.Type.LOWER_UTILIZATION
        );
    },
    dispute: () => (state: RootState) => {
      return state.coaching.suggested
        ?.concat(state.coaching.inProgress)
        .map((token) => selectTask(token)(state))
        .filter(
          (task) =>
            task?.annotationComment &&
            task.type === web.public_.CoachingTask.Type.DISPUTE
        );
    },
    reopenAccount: () => (state: RootState) => {
      return state.coaching.suggested
        ?.concat(state.coaching.inProgress)
        .map((token) => selectTask(token)(state))
        .filter(
          (task) =>
            task?.annotationComment &&
            task.type === web.public_.CoachingTask.Type.REOPEN_ACCOUNT
        );
    },
  }
);

function resolveRecommendationsInTasks(
  tasks: web.public_.ICoachingTask[],
  recommendations: web.public_.IRecommendation[]
): CoachingTask[] {
  const {
    CBL_HIGH_SCORE,
    CBL_LOW_SCORE,
    CA_PAYMENT_30_OVERDUE,
    CA_PAYMENT_90_OVERDUE,
    PAYMENT_90_OVERDUE,
    PAYMENT_120_OVERDUE,
    DISPUTES_LATE_PAYMENT,
    DISPUTES_OLD,
    DISPUTES_REDISPUTE,
  } = web.public_.Recommendation.Variant;

  const tasksWithRecommendationAccounts: CoachingTask[] = tasks.map((task) => {
    switch (task.type) {
      case web.public_.CoachingTask.Type.DISPUTE_LATE_PAYMENT: {
        const recommendation = recommendations.find((recommendation) =>
          [DISPUTES_LATE_PAYMENT].includes(recommendation.variant)
        );
        const recommendationAccount =
          recommendation?.subject?.disputesLatePayment;

        return {
          ...task,
          recommendation,
          invalidRecommendation: !recommendationAccount,
        };
      }
      case web.public_.CoachingTask.Type.DISPUTE_OLD_ACCOUNT: {
        const recommendation = recommendations.find((recommendation) =>
          [DISPUTES_OLD].includes(recommendation.variant)
        );
        const recommendationAccount =
          recommendation?.subject?.disputesOld?.accounts?.[0];

        return {
          ...task,
          recommendation,
          invalidRecommendation: !recommendationAccount,
        };
      }
      case web.public_.CoachingTask.Type.DISPUTE_REDISPUTE: {
        const recommendation = recommendations.find((recommendation) =>
          [DISPUTES_REDISPUTE].includes(recommendation.variant)
        );
        const recommendationAccount =
          recommendation?.subject?.disputesRedispute?.accounts?.[0];

        return {
          ...task,
          recommendation,
          invalidRecommendation: !recommendationAccount,
        };
      }
      case web.public_.CoachingTask.Type.LATE_ACCOUNT: {
        const recommendation = recommendations.find((recommendation) =>
          [PAYMENT_90_OVERDUE, PAYMENT_120_OVERDUE].includes(
            recommendation.variant
          )
        );
        const recommendationAccount =
          recommendation?.subject?.payment_90Overdue ??
          recommendation?.subject?.payment_120Overdue;

        return {
          ...task,
          recommendation,
          invalidRecommendation: !recommendationAccount,
        };
      }
      case web.public_.CoachingTask.Type.LATE_CA_PAYMENT: {
        const recommendation = recommendations.find((recommendation) =>
          [CA_PAYMENT_30_OVERDUE, CA_PAYMENT_90_OVERDUE].includes(
            recommendation.variant
          )
        );
        const recommendationAccount =
          recommendation?.subject?.caPayment_30Overdue ??
          recommendation?.subject?.caPayment_90Overdue;

        return {
          ...task,
          recommendation,
          invalidRecommendation: !recommendationAccount,
        };
      }
      case web.public_.CoachingTask.Type.OPEN_CBL: {
        const recommendation = recommendations.find((recommendation) =>
          [CBL_HIGH_SCORE, CBL_LOW_SCORE].includes(recommendation.variant)
        );
        const recommendationAccount =
          recommendation?.subject?.cblLowScore ??
          recommendation?.subject?.cblHighScore;

        return {
          ...task,
          recommendation,
          invalidRecommendation: !recommendationAccount,
        };
      }
      default: {
        return { ...task };
      }
    }
  });

  return tasksWithRecommendationAccounts;
}

export const fetchTasks = () =>
  thunk((dispatch) =>
    Promise.all([
      webRPC.Coaching.listTasks({}).then<web.public_.ICoachingTask[]>(
        handleProtoStatus({
          SUCCESS({ tasks }) {
            return tasks;
          },
          _DEFAULT: handleFailedStatus("Failed to fetch tasks."),
        })
      ),
      webRPC.Recommendations.getRecommendations({}).then(
        handleProtoStatus({
          SUCCESS(data) {
            return data.recommendations;
          },
          _DEFAULT: handleFailedStatus("Failed to load recommendations."),
        })
      ),
      dispatch(initCreditV2()),
    ]).then(([tasks, recommendations, credit]) => {
      const tasksWithRecommendationAccounts = resolveRecommendationsInTasks(
        tasks,
        recommendations
      );

      dispatch(
        actions.updateTasksByToken(
          Object.fromEntries(
            tasksWithRecommendationAccounts.map((task) => [task.token, task])
          )
        )
      );
    })
  );

export const addTaskToList = (token: CoachingTask.Token) =>
  thunk((dispatch) =>
    webRPC.Coaching.addTaskToList({ token }).then(
      handleProtoStatus({
        SUCCESS({ task }) {
          dispatch(actions.updateTasksByToken({ [token]: task }));
        },
        _DEFAULT: handleFailedStatus("Failed to add task to list."),
      })
    )
  );

export const markTaskAsComplete = (token: CoachingTask.Token) =>
  thunk((dispatch, getState) => {
    const task = selectTask(token)(getState());

    dispatch(
      actions.updateTasksByToken({
        [token]: {
          ...task,
          status: web.public_.CoachingTask.Status.MARKED_AS_COMPLETE,
        },
      })
    );

    return webRPC.Coaching.markTaskAsComplete({ token })
      .then(
        handleProtoStatus({
          SUCCESS({ task }) {
            dispatch(actions.updateTasksByToken({ [token]: task }));
          },
          _DEFAULT: handleFailedStatus("Failed to mark task as completed."),
        })
      )
      .catch((e) => {
        dispatch(actions.updateTasksByToken({ [token]: task }));
        throw e;
      });
  });

export const dismissTask = (token: CoachingTask.Token) =>
  thunk((dispatch, getState) => {
    const task = selectTask(token)(getState());
    dispatch(actions.dismiss(token));
    return webRPC.Coaching.dismissTask({ token })
      .then(
        handleProtoStatus({
          SUCCESS() {},
          _DEFAULT: handleFailedStatus("Failed to dismiss task."),
        })
      )
      .catch((e) => {
        dispatch(actions.updateTasksByToken({ [token]: task }));
        throw e;
      });
  });
